<template>
    <v-select v-model="detalle"
              :dense="dense"
              :disabled="disabled"
              :items="items"
              item-text="name"
              item-value="id"
              attach
              chips
              label="Modulos"
              @change="change"
              multiple
              :menu-props="{ auto: true, botton: true, offsetY: true }">
    </v-select>
    <!--<v-card class="mb-5">
        <v-card-actions class="pa-0">
            <v-checkbox class="pl-10"
                        v-model="baja"
                        @change="changeBaja"
                        label="Baja" />
            <v-checkbox class="pl-10"
                        v-model="seguimiento"
                        @change="changeSeguimiento"
                        label="Seguimiento" />
            <v-checkbox class="pl-10"
                        v-model="cia"
                        @change="changeCia"
                        label="Compañia" />
        </v-card-actions>
    </v-card>-->
</template>

<script>
    export default {
        name: 'selectModulo',

        props: ['value', 'disabled', 'dense'],

        data() {
            return {
                items: [
                    { id: 1, name: 'Baja' },
                    { id: 2, name: 'Seguimiento' },
                    { id: 4, name: 'Cliente' },
                    { id: 8, name: 'Guarda' },
                    { id: 16, name: 'Guarda Extras' }],

            };
        },

        computed: {
            detalle() {
                let ret = [];
                this.items.forEach(i => {
                    if (this.value & i.id) {
                        ret.push(i.id)
                    }
                });
                return ret;
            },
        },

        methods: {
            change(value) {
                let ret = 0;
                value.forEach(i => {
                    ret |= i;
                });
                this.$emit('input', ret);
            },
        }
    };
</script>
