<template>
    <v-data-table :headers="headers"
                  dense
                  :items="all.items"
                  sort-by="id"
                  :search="search"
                  class="elevation-1"
                  @click:row="editItem">
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Estado</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog"
                          max-width="850px"
                         persistent>
                    <template v-slot:activator>
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               v-on:click="editItem(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-row justify="space-around">
                                    <v-layout wrap>
                                        <v-flex xs6 sm6>
                                            <v-text-field v-model="item.nombre"
                                                          label="Nombre">
                                            </v-text-field>
                                        </v-flex>

                                        <v-flex xs6 sm6>
                                            <v-chip :text-color="item.textColor"
                                                    :color="item.color"
                                                    @click="dialogColor = true"
                                                    dark>{{ item.nombre }}</v-chip>
                                        </v-flex>

                                        <v-flex xs12 sm8>
                                            <estadoTramite :estadoItem="item"
                                                           :tramites="item.estadoTramite"
                                                           @guarda="item = $event">
                                            </estadoTramite>
                                        </v-flex>

                                        <v-flex xs12 sm3>
                                            <selectModulo v-model="item.modulo" />
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <colores :color="item.color"
                                 :textColor="item.textColor"
                                 :texto="item.nombre"
                                 v-model="dialogColor"
                                 @chgColor="setColor($event)"
                                 @chgTextColor="setTextColor($event)"></colores>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancela</v-btn>
                            <v-btn color="blue darken-1" text @click="save">Guarda</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.nombre="{ item }">
            <v-chip class="mx-2"
                    small
                    :text-color="item.textColor"
                    :color="item.color"
                    dark>{{ item.nombre }}</v-chip>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>
        <template v-slot:item.tramite>
            <v-row>
                <v-chip v-for="(item, inx) in item.estadoTramite"
                        :key="inx"
                        class="mr-2"
                        justify="space-around"
                        x-small
                        :color="item.default ? 'success' : 'primary'">
                    {{ item.tramite.nombre }}
                </v-chip>
            </v-row>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import colores from '@/components/Tools/Colores';
    import estadoTramite from '@/components/Estado/EstadoTramite';
    import selectModulo from '@/components/Selects/selectModulo';

    export default {
        name: 'estado',
        data() {
            return {
                dialog: false,
                dialogColor: false,
                search: '',
                formTitle: '',
                headers: [
                    { text: 'Id', align: 'left', sortable: true, value: 'id' },
                    {
                        text: 'Nombre',
                        value: 'nombre',
                        color: 'blue',
                        textColor: 'blue'
                    },
                    { text: 'Tramites', value: 'tramite', sortable: false },
                    { text: 'Opciones', value: 'action', sortable: false }
                ]
            };
        },

        computed: {
            ...mapState('estado', ['all', 'item', 'defaultItem', 'index'])
        },

        created() {
            this.initialize();
        },

        watch: {
            dialog(val) {
                val || this.close();
            }
        },

        methods: {
            ...mapActions('estado', [
                'getAll',
                'getById',
                'setTextColor',
                'setColor',
                'putObject',
                'postObject',
                'deleteObject',
                'setItem',
                'setIndex'
            ]),

            initialize() {
                this.getAll();
                this.setItem(Object.assign({}, this.defaultItem));
            },

            editItem(itm) {
                if (itm == null) {
                    this.formTitle = 'Nuevo estado';
                    this.setItem(Object.assign({}, this.defaultItem));
                    this.setIndex(-1);
                } else {
                    this.formTitle = 'Edita estado';
                    var e = this.all.items.findIndex(x => x.id == itm.id);
                    this.setIndex(e);
                    this.setItem(Object.assign({}, itm));
                }
                this.dialog = true;
            },

            deleteItem(item) {
                const index = this.all.items.indexOf(item);
                if (confirm('Desea borrar el estado?')) {
                    this.deleteObject(item.id);
                    this.all.items.splice(index, 1);
                }
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.setItem(Object.assign({}, this.defaultItem));
                    this.setIndex(-1);
                }, 300);
            },

            save() {
                if (this.index > -1) {
                    this.putObject(this.item);
                    Object.assign(this.all.items[this.index], this.item);
                } else {
                    this.postObject(this.item).then(registro => {
                        this.all.items.push(registro);
                    });
                }

                this.close();
            }
        },
        components: {
            colores,
            estadoTramite,
            selectModulo
        }
    };
</script>
