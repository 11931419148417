<template>
    <div>
        <v-icon small @click.stop="sube" :disabled="value <= 1">
            mdi-delta
        </v-icon>
        <v-icon small @click.stop="baja" :disabled="value >= max">
            mdi-details
        </v-icon>
    </div>
</template>

<script>
export default {
    name: 'ArribaAbajo',
    props: ['value', 'max'],
    data() {
        return {};
    },
    methods: {
        sube() {
            if (this.value >= 1) {
                this.$emit('input', this.value - 1);
                this.$emit('change', -1);
            }
        },

        baja() {
            if (this.value < this.max) {
                this.$emit('input', this.value + 1);
                this.$emit('change', 1);
            }
        }
    }
};
</script>
