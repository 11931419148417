<template>
    <v-list-item v-if="valores.logout!=true" link :to="valores.ruta">
        <v-chip :color="config.menuColor" :dark="config.dark == '1' || this.config.dark == 'true'">
            <v-list-item-icon>
                <v-icon>{{ valores.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="valores.title"></v-list-item-title>
            </v-list-item-content>
        </v-chip>
    </v-list-item>
    <v-list-item v-else link @click="$emit('logout')">
        <v-chip :color="config.menuColor" :dark="config.dark == '1' || this.config.dark == 'true'">
            <v-list-item-icon>
                <v-icon>{{ valores.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="valores.title"></v-list-item-title>
            </v-list-item-content>
        </v-chip>
    </v-list-item>
</template>


<script>
export default {
  name: "navDrawerItem",
  props: ["valores", "config"],
  data() {
    return {};
  },
  methods: {
    showed(item) {
      return item.show != false;
    }
  }
};
</script>

<style>
</style>