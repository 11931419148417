<template>
    <v-data-table
        :headers="headers"
        dense
        :items="all.items"
        sort-by="id"
        :search="search"
        class="elevation-1"
        @click:row="editItem"
    >
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Marca</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                    <template v-slot:activator>
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="warning"
                            v-on:click="editItem(null)"
                        >
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6 md4>
                                            <v-text-field
                                                v-model="item.nombre"
                                                label="Nombre"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close"
                                >Cancela</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="save"
                                >Guarda</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'marca',
    data() {
        return {
            dialog: false,
            search: '',
            headers: [
                {
                    text: 'Id',
                    align: 'left',
                    sortable: true,
                    value: 'id'
                },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Opciones', value: 'action', sortable: false }
            ]
        };
    },

    computed: {
        ...mapState('marca', ['all', 'item', 'defaultItem', 'index']),
        formTitle() {
            return this.index === -1 ? 'Nuevo marca' : 'Edita marca';
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        ...mapActions('marca', [
            'getAll',
            'putObject',
            'postObject',
            'deleteObject',
            'setItem',
            'setIndex'
        ]),

        initialize() {
            this.getAll();
            this.setItem(Object.assign({}, this.defaultItem));
        },

        editItem(itm) {
            if (itm == null) {
                this.setItem(Object.assign({}, this.defaultItem));
                this.setIndex(-1);
            } else {
                var e = this.all.items.findIndex(x => x.id == itm.id);
                this.setIndex(e);
                this.setItem(Object.assign({}, itm));
            }
            this.dialog = true;
        },

        deleteItem(item) {
            const index = this.all.items.indexOf(item);
            if (confirm('Desea borrar el marca?')) {
                this.deleteObject(item.id);
                this.all.items.splice(index, 1);
            }
        },

        close() {
            this.dialog = false;
            setTimeout(() => {
                this.setItem(Object.assign({}, this.defaultItem));
                this.setIndex(-1);
            }, 300);
        },

        save() {
            if (this.index > -1) {
                this.putObject(this.item);
                Object.assign(this.all.items[this.index], this.item);
            } else {
                this.postObject(this.item).then(marca => {
                    this.all.items.push(marca);
                });
            }
            this.close();
        }
    }
};
</script>
