<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <v-flex xs6 sm6>
                    <v-chip :text-color="textColor" :color="color" dark>
                        {{ texto }}
                    </v-chip>
                </v-flex>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-row justify="space-around">
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <label>
                                    Color
                                </label>
                                <v-color-picker
                                    class="ma-2"
                                    v-model="color"
                                    hide-mode-switch
                                    hide-inputs
                                    hide-swatches
                                    @color="CambioTextColor"
                                >
                                </v-color-picker>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <label>
                                    Color del texto
                                </label>
                                <v-color-picker
                                    class="ma-2"
                                    v-model="textColor"
                                    hide-mode-switch
                                    hide-inputs
                                    hide-swatches
                                    @color="CambioColor"
                                >
                                </v-color-picker>
                            </v-flex>
                        </v-layout>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancela</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guarda</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'colores',
    props: ['texto', 'color', 'textColor', 'value'],
    data() {
        return {};
    },
    methods: {
        CambioTextColor() {},
        CambioColor() {},
        close() {
            this.value = false;
            this.$emit('input', this.value);
        },

        save() {
            this.$emit('chgTextColor', this.textColor);
            this.$emit('chgColor', this.color);
            this.close();
        }
    }
};
</script>
