import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'api/modelovalor/';

// Exporta metodos
export const modelovalorService = {
    getAll,
    getById,
    getByMarcaModeloTipo,
    postObject,
};

// Lee todos los elementos
function getAll() {
    return axios.get(urlAPI + API, authHeader()).then(registro => {
        return registro.data;
    });
}

async function getById(id) {
    return await axios.get(urlAPI + API + id, authHeader()).then(registro => {
        return registro.data;
    });
}

async function getByMarcaModeloTipo(pl) {
        var ret = await axios.post(urlAPI + API + "MarcaModeloTipo", pl, authHeader());
        return ret.data;
}

// Post de la entidad
function postObject(obj) {
    // Copiar todas las propiedades menos id
    return axios.post(urlAPI + API + 'valores', obj, authHeader()).then(registro => {
        return registro.data;
    });
}

