<template>
    <v-data-table :headers="headers"
                  dense
                  disable-pagination
                  :items="item.desTVhTBjDTO"
                  hide-default-footer
                  class="elevation-1">

        <template v-slot:item.porcentaje="{item}">
            <v-edit-dialog :return-value.sync="item.porcentaje"
                           large
                           persistent
                           save-text="Confirma"
                           cancel-text="Cancela">
                <div>{{ item.porcentaje }}</div>
                <template v-slot:input>
                    <div class="mt-4 title">actualiza porcentaje</div>
                </template>
                <template v-slot:input>
                    <v-text-field v-model="item.porcentaje"
                                  :rules="[numerico]"
                                  label="Porcentaje"
                                  single-line
                                  counter
                                  autofocus></v-text-field>
                </template>
            </v-edit-dialog>
        </template>

    </v-data-table>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'desarmaderoTVehiculoTBaja',
        data() {
            return {
                dialog: false,

                headers: [
                    { text: 'Tipo de Vehiculo', value: 'tVehiculo' },
                    { text: 'Tipo de baja', value: 'tBaja', sortable: false },
                    { text: 'Porcentaje', value: 'porcentaje', sortable: false }
                ],
                defaultItem: {
                    desarmaderoId: 0,
                }
            };
        },

        computed: {
            ...mapState('desarmadero', ['item']),
        },

        watch: {
            dialog(val) {
                val || this.close();
            }
        },

        created() {
        },

        methods: {
            numerico(/*num*/) {
                return true;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedIndex = -1;
                }, 300);
            },

        },
        components: {
        }
    };
</script>
