<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="save">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row justify="space-around">
                    <v-layout wrap>
                        <v-flex xs12 sm4>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm4>
                            <v-text-field v-model="item.direccion"
                                          label="Dirección"
                                          @change="change">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <selectClientes v-model="item.cliGes"
                                            @change="changeCliente(item.cliGes=$event)"
                                            sm5></selectClientes>
                        </v-flex>

                        <v-flex xs12 sm4>
                            <selectProvincia v-model="item.provincia.id"
                                             :texto="item.provincia.nombre"
                                             @texto="item.provincia.nombre = $event"
                                             @change="change">
                            </selectProvincia>
                        </v-flex>

                        <v-flex xs12 sm4>
                            <selectLocalidad v-model="item.localidad.id"
                                             :texto="item.localidad.nombre"
                                             :provinciaId="item.provincia.id"
                                             @texto="item.localidad.nombre = $event"
                                             @change="change">
                            </selectLocalidad>
                        </v-flex>

                        <v-flex xs12 md4>
                            <v-text-field v-model="item.cuit"
                                          label="CUIT">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 md4>
                            <selectZonainfluencia v-model="item.zonainfluencia.id"
                                                  :texto="item.zonainfluencia.nombre"
                                                  @texto="item.zonainfluencia.nombre = $event">
                            </selectZonainfluencia>
                        </v-flex>

                        <v-flex xs12 md4>
                            <v-text-field v-model="item.telefono"
                                          label="Teléfono">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 md8>
                            <v-text-field v-model="item.email"
                                          label="e-mail">
                            </v-text-field>
                        </v-flex>

                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import selectLocalidad from '@/components/Selects/selectLocalidad';
    import selectProvincia from '@/components/Selects/selectProvincia';
    import selectZonainfluencia from '@/components/Selects/selectZonaInfluencia';
    import selectClientes from '@/components/Selects/selectClientes';

    export default {
        name: 'gestorDialogo',
        data() {
            return {
            };
        },

        computed: {
            ...mapState('gestor', [
            'item', 
            'id', 
            'modificado'
            ]),
            ...mapState('account', [
            'permisos', 
            'loggedUser'
            ]),

            formTitle() {
                return (this.item == undefined || this.item.id == 0)
                    ? 'Nuevo gestor'
                    : `Gestor ${this.item.nombre === undefined ? '' : this.item.nombre
                    }`;
            },
        },

        methods: {
            ...mapActions('gestor', [
                'closeDialog',
                'setDefaultItem',
                'saveItem',
                'setModificado',
                'setCliGes'
                ]),

            cancela() {
                this.closeDialog();
            },

            change() {
                this.setModificado(true);
            },

            changeCliente(event) {
                this.setCliGes(event);

                this.setModificado(true);
            },

            close() {
                if (!this.loggedUser.loggedIn)
                    this.$router.push('/');

                this.setDefaultItem();

                this.closeDialog();            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            save() {
                this.saveItem();
                this.cancela();
            },

        },

        components: {
            selectClientes,
            selectLocalidad,
            selectProvincia,
            selectZonainfluencia,
        }
    };
</script>