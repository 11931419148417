import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlImageAPI } from './index';

// ruta API de la entidad
let API = 'api/image/';

// Exporta metodos
export const imageService = {
    getAll,
    putObject,
    postObject,
    deleteObject
};

// Lee todos los elementos
async function getAll(folder) {
    return axios.get(urlImageAPI + API + "thumbnails/" + folder, authHeader()).then(registro => {
        return registro.data;
    });
}

// Post de la entidad
function postObject(obj) {
    return axios.post(urlImageAPI + API + obj.domain, obj.payload, authHeader()).then(registro => {
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var modelo = {
        id: obj.id,
        nombre: obj.nombre,
        borrado: obj.borrado
    };
    return axios
        .put(urlImageAPI + API + modelo.id, modelo, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// delete de la entidad
function deleteObject(obj) {
    return axios.delete(urlImageAPI + API + obj.domain + '/' + obj.image, obj.payload, authHeader()).then(registro => {
        return registro.data;
    });
}
