<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="save">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row justify="space-around">
                    <v-layout wrap>
                        <v-flex xs12 sm3>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm4>
                            <v-text-field v-model="item.direccion"
                                          label="Dirección"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm2>
                            <selectProvincia v-model="item.provincia.id"
                                             :texto="item.provincia.nombre"
                                             @texto="item.provincia.nombre = $event"
                                             @change="change">
                            </selectProvincia>
                        </v-flex>
                        <v-flex xs12 sm3>
                            <selectLocalidad v-model="item.localidad.id"
                                             :texto="item.localidad.nombre"
                                             :provinciaId="item.provincia.id"
                                             @texto="item.localidad.nombre = $event"
                                             @change="change">
                            </selectLocalidad>
                        </v-flex>


                        <v-flex xs12 sm5>
                            <desarmaderoTVehiculoTBaja :estadoItem="item"
                                                       :Clientes="item.desarmaderoTVehiculoTBaja"
                                                       @guarda="item = $event"
                                                       @change="change">
                            </desarmaderoTVehiculoTBaja>
                        </v-flex>

                        <v-flex xs12 md7>
                            <v-card>
                                <v-card-text>
                                    <v-container grid-list-md>
                                        <v-row justify="space-around">
                                            <v-layout wrap>

                                                <v-flex xs12 sm6 md3>
                                                    <selectZonainfluencia v-model="item.zonainfluencia.id"
                                                                          :texto="item.zonainfluencia.nombre"
                                                                          @texto="item.zonainfluencia.nombre = $event"
                                                                          @change="change">
                                                    </selectZonainfluencia>
                                                </v-flex>

                                                <v-flex xs12 md4>
                                                    <v-text-field v-model="item.telefono"
                                                                  label="Teléfono"
                                                                  @change="change">
                                                    </v-text-field>
                                                </v-flex>

                                                <v-flex xs12 md4>
                                                    <v-text-field v-model="item.cuit"
                                                                  label="CUIT"
                                                                  @change="change">
                                                    </v-text-field>
                                                </v-flex>

                                                <v-flex xs12 md6>
                                                    <v-text-field v-model="item.email"
                                                                  label="e-mail"
                                                                  @change="change">
                                                    </v-text-field>
                                                </v-flex>

                                                <v-flex xs12 sm4>
                                                    <v-text-field v-model="item.contacto"
                                                                  label="Contacto"
                                                                  @change="change">
                                                    </v-text-field>
                                                </v-flex>

                                                <v-flex xs12 md3>
                                                    <v-text-field v-model="item.codRUDAC"
                                                                  label="cod RUDAC"
                                                                  @change="change">
                                                    </v-text-field>
                                                </v-flex>

                                                <v-flex xs12 md5>
                                                    <v-text-field v-model="item.nombreRUDAC"
                                                                  label="nombre RUDAC"
                                                                  @change="change">
                                                    </v-text-field>
                                                </v-flex>

                                                <v-flex xs12 md4>
                                                    <fecha v-model="item.vencimientoRUDAC"
                                                           label="venc. RUDAC"
                                                           @change="change">
                                                    </fecha>
                                                </v-flex>
                                            </v-layout>
                                            <v-flex xs12>
                                                <selectClientes v-model="item.cliDes"
                                                                @change="changeCliente(item.cliDes=$event)"
                                                                sm5></selectClientes>
                                            </v-flex>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-flex>-->
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>
        <v-dialog v-model="cierraDialog" max-width="300px">
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dense color="yellow">
                        <v-toolbar-title>
                            Hay cambios pendientes...
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-10"> </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancela">
                        Cancela
                    </v-btn>
                    <v-btn color="error" @click="save">
                        Guarda
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import desarmaderoTVehiculoTBaja from '@/components/Desarmadero/DesarmaderoTVehiculoTBaja';
    import selectLocalidad from '@/components/Selects/selectLocalidad';
    import selectProvincia from '@/components/Selects/selectProvincia';
    import selectZonainfluencia from '@/components/Selects/selectZonaInfluencia';
    import selectClientes from '@/components/Selects/selectClientes';
    import fecha from '@/components/Tools/Fecha';

    export default {
        name: 'desarmaderoDialogo',
        data() {
            return {
                cierraDialog: false,
            };
        },

        computed: {
            ...mapState('desarmadero', ['item', 'id', 'modificado']),
            ...mapState('account', ['permisos', 'loggedUser']),

            formTitle() {
                return (this.item == undefined || this.item.id == 0)
                    ? 'Nuevo desarmadero'
                    : `Desarmadero ${this.item.nombre === undefined ? '' : this.item.nombre
                    }`;
            },
        },

        methods: {
            ...mapActions('desarmadero', [
                'closeDialog',
                'setDefaultItem',
                'saveItem',
                'setModificado',
                'setCliDes']),

            cancela() {
                this.closeDialog();
            },

            change() {
                this.setModificado(true);
            },

            changeCliente(event) {
                this.setCliDes(event);

                this.setModificado(true);
            },

            close() {
                if (!this.loggedUser.loggedIn)
                    this.$router.push('/');

                this.setDefaultItem();

                this.closeDialog();
            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            save() {
                this.saveItem();
                this.cancela();
            },

        },

        components: {
            desarmaderoTVehiculoTBaja,
            fecha,
            selectClientes,
            selectLocalidad,
            selectProvincia,
            selectZonainfluencia,
        }
    };
</script>