import { tramiteService } from '../_services';
import { router } from '../_helpers';

let service = tramiteService;

const state = {
    items: [],
    item: {},
    id: 0,
    progress: false,
    dialog: false,
    borraDialog: false,
    deflt: {
        id: 0,
        orden: 0,
        nombre: '',
        estadoInicialId: 0,
        estadoInicial: { nombre: '', id: 0 },
        estadoFinalId: 0,
        estadoFinal: { nombre: '', id: 0 },
        modulo: 0,
        tramiteCampo: []
    }
};

const getters = {
    getTramiById: state => id => {
        return state.items.find(todo => todo.id === id);
    }
};

const actions = {
    initialize({ dispatch }) {
        dispatch('getAll');
        dispatch('campotramite/getAll', null, { root: true });
    },

    editItem({ dispatch, commit }, itm) {
        if (state.dialog) commit('setDialog', false);

        if (itm === null) {
            commit('setDefaultItem');
        } else {
            dispatch('getById', itm.id);
        }
        commit('setDialog', true);
    },

    borraItem({ dispatch, commit }, itm) {
        commit('setItem', itm);
        commit('setBorraDialog', true);
    },

    getxestado({ commit }, estadoId) {
        commit('getAllRequest');

        service.getxestado(estadoId).then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    changePosition({ dispatch, commit }, payload) {
        let orden = payload.item.orden;
        let inc = payload.inc;
        let id = payload.item.id;

        state.items.find(function (reg) {
            if (inc > 0) {
                if (id != reg.id && reg.orden == orden) {
                    reg.orden--;
                    dispatch('updateOrden', reg);
                }
            } else {
                if (id != reg.id && reg.orden == orden) {
                    reg.orden++;
                    dispatch('updateOrden', reg);
                }
            }
        });

        dispatch('updateOrden', payload.item);
    },

    updateOrden({ commit }, payload) {
        service
            .postOrdenObject({
                id: payload.id,
                orden: payload.orden,
                nombre: '.'
            })
            .then(
                registro => commit('changePositionSuccess', registro),
                error => {
                    commit('failure', error);
                    state.error = true;
                    dispatch('alert/errorTime', error.response.data, {
                        root: true
                    });
                }
            );
    },

    getById({ dispatch, commit, state }, id) {
        var self = this;

        commit('getByRequest');

        service.getById(id).then(
            registro => {
                commit('getBySuccess', registro);
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    postObject({ dispatch, commit, state }) {
        commit('postRequest', state.item);

        return service.postObject(state.item).then(
            registro => {
                commit('postSuccess', registro);
                return registro;
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    saveObject({ dispatch, commit }) {
        if (state.item.id > 0) {
            dispatch('putObject');
        } else {
            dispatch('postObject');
        }
        commit('setDialog', false);
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },

    setBorraDialog({ commit }, payload) {
        commit('setBorraDialog', payload);
    },

    putObject({ dispatch, commit, state }) {
        commit('putRequest', state.item);

        service.putObject(state.item).then(
            registro => commit('putSuccess', registro),
            error => {
                commit('failure', error);
                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    },

    deleteObject({ dispatch, commit, state }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            user => commit('deleteSuccess', id),
            error => {
                commit('failure', error);
                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
        commit('setBorraDialog', false);
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
    },

    closeBorraDialog({ commit }) {
        commit('setBorraDialog', false);
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setItems({ commit }, payload) {
        commit('setItems', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    },

    setAlerta({ commit }, payload) {
        commit('setAlerta', payload);
    }
};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.all = { loading: true };
        state.progress = true;
    },

    getAllSuccess(state, registro) {
        state.items = registro;
        state.progress = false;
    },

    failure(state, error) {
        state.id = 0;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.modificado = false;
        state.id = registro.id;
        state.progress = false;
    },

    changePositionSuccess(state, payload) { },

    changePosition(state, payload) {
        let orden = payload.item.orden;
        let inc = payload.inc;

        state.items.find(function (reg) {
            if (inc > 0) {
                if (reg.orden == orden) {
                    reg.orden--;
                }
            } else {
                if (reg.orden == orden) {
                    reg.orden++;
                }
            }
        });

        payload.item.orden += inc;

        //commit('setDefault', { item, incrementa });
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },
    postSuccess(state, registro) {
        state.items.push(registro);
        state.item = registro;
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },

    putSuccess(state, registro) {
        var i = state.items.find(x => x.id == registro.id);
        var index = state.items.indexOf(i);
        state.progress = false;
        state.items.splice(index, 1, JSON.parse(JSON.stringify(registro)));
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to user being deleted
        state.items = state.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },

    deleteSuccess(state, id) {
        // remove deleted user from state
        state.items = state.items.filter(user => user.id !== id);
        state.progress = false;
    },

    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.items = state.items.map(user => {
            if (user.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...userCopy } = user;
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error };
            }

            return user;
        });
        state.progress = false;
    },

    setDialog(state, payload) {
        state.dialog = payload;
    },

    setBorraDialog(state, payload) {
        state.borraDialog = payload;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    },

    setDefaultItem(state, rootState) {
        state.item = JSON.parse(JSON.stringify(state.deflt));
        state.item.orden = state.items.length + 1;
        if (!this.state.campotramite.progress)
            this.state.campotramite.items.forEach((val) => {

                state.item.tramiteCampo.push({
                    borrado: false,
                    campo: val.nombre,
                    campoTramite: null,
                    campoTramiteId: val.id,
                    id: 0,
                    nombre: val.nombre,
                    obligatorio: false,
                    tramite: null,
                    tramiteId: 0,
                    visible: false,
                });

            });
    },

    setItems(state, payload) {
        state.items = payload;
        if (payload.id == 0) {
            state.index = -1;
        }
    },

    pushChild(/*state, payload*/) {
        //state.item.casoTramite.push(payload);
    },

    setAlerta(state, payload) {
        var estado =
            state.item.casoAlerta.find(x => x.alertaId == payload.alertaId) !=
            null;

        if (payload.value) {
            if (!estado) {
                state.item.casoAlerta.push({
                    alertaId: payload.alertaId,
                    applicationUserId: 1,
                    fInicio: null,
                    fCierre: null,
                    descripcion: ''
                });
            }
        } else {
            if (estado) {
                var indice = state.item.casoAlerta.indexOf(
                    state.item.casoAlerta.find(
                        x => x.alertaId == payload.alertaId
                    )
                );
                if (indice >= 0) {
                    state.item.casoAlerta.splice(indice, 1);
                }
            }
        }
    }
};

export const tramite = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
