<template>
    <v-toolbar-items class="hidden-sm-and-down">
        <v-menu left :offset-x="offsetX" :offset-y="offsetY">
            <template v-slot:activator="{ on }">
                <v-list-item-avatar v-on="on">
                    <!--<p>{{user.abrv}}</p>-->
                    <v-img v-if="user.loggedIn && user.avatar!=''" :src="user.avatar"></v-img>
                    <v-icon large v-else>mdi-account</v-icon>
                </v-list-item-avatar>
            </template>
            <v-list :color="config.navColor" class="pa-0">
                <div v-for="(item,key, index) in menu" :key="index">
                    <v-list-item class="pl-1 pr-1" :color="config.menuColor" :dark="config.dark" :to="item.ruta">
                        <navItem :config="config"
                                 :valores="item"
                                 @logout="$emit('logout')"></navItem>
                    </v-list-item>
                </div>
            </v-list>
        </v-menu>
    </v-toolbar-items>
</template>

<script>
    import navItem from "@/components/Tools/Navigation/navItem";

    export default {
        name: "navMenuItem",
        props: ["user", "menu", "navColor", "config"],
        data() {
            return {
                offsetX: false,
                offsetY: true
            };
        },
        methods: {
            logout() {
                this.$emit('logout');
            }
        },
        components: {
            navItem
        }
    };
</script>

<style>
</style>