<template>
    <v-data-table :headers="headers"
                  dense
                  disable-pagination
                  hide-default-footer
                  :items="items"
                  sort-by="id"
                  :search="search"
                  class="elevation-1"
                  @click:row="editItem">
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Gestor</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              hide-details></v-text-field>
                <v-spacer></v-spacer>

                <v-dialog v-model="propDialog" width="50%" max-width="800px" persistent>
                    <template v-slot:activator>
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               v-on:click="editItem(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <gestorDialogo></gestorDialogo>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import gestorDialogo from '@/components/Gestor/GestorDialogo';

    export default {
        name: 'gestor',
        data() {
            return {
                search: '',
                formTitle: '',
                headers: [
                    { text: 'Id', align: 'left', sortable: true, value: 'id' },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Dirección', value: 'direccion' },
                    { text: 'Localidad', value: 'localidad' },
                    { text: 'Provincia', value: 'provincia' },
                    { text: 'CUIT', value: 'cuit' },
                    { text: 'Zona Influencia', value: 'zonainfluencia' },
                    { text: 'Opciones', value: 'action', sortable: false }
                ]
            };
        },

        computed: {
            ...mapState('gestor', [
                'items',
                'dialog',
                'item',
                'progress']),

            propDialog: {
                get() {
                    return this.dialog;
                },
                set(value) {
                    this.setDialog(value);
                }
            },
        },

        async created() {
            var self = this;
            await this.initialize();
            await self.getAllAsync();
        },

        methods: {
            ...mapActions('gestor', [
                'initialize',
                'editItem',
                'getAllAsync',
                'setDialog',
                'putObject',
                'postObject',
                'deleteObject',
                'setIndex'
            ]),


            deleteItem(item) {
                const index = this.items.indexOf(item);
                if (confirm('Desea borrar el gestor?')) {
                    this.deleteObject(item.id);
                    this.items.splice(index, 1);
                }
            },


            selectRow(itm) {
                this.editItem(itm);
            },

        },

        components: {
            gestorDialogo,
        }
    };
</script>
