import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'identity/Role/';

export const roleService = {
    getAll,
    getById,
    putObject,
    postObject,
    delete: _delete
};

function getAll() {
    let s = authHeader();
    return axios.get(urlAPI + API, s, authHeader()).then(registro => {
        return registro.data;
    });
}

function getById(id) {
    let s = authHeader();
    return axios.get(urlAPI + API + id, s, authHeader()).then(registro => {
        return registro.data;
    });
}

// Post de la entidad
function postObject(obj) {
    let s = authHeader();
    // Copiar todas las propiedades menos id
    var modelo = {
        name: obj.name
    };
    return axios.post(urlAPI + API, modelo, s, authHeader()).then(registro => {
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var modelo = {
        id: obj.id,
        name: obj.name
    };
    return axios
        .put(urlAPI + API + modelo.id, modelo, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(/*id*/) {
    //const requestOptions = {
    //    method: 'DELETE',
    //    headers: authHeader()
    //};
    //return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

//function handleResponse(response) {
//return response.then(text => {
//    const data = text && JSON.parse(text);
//    if (!response.status === 200) {
//        if (response.status === 401) {
//            // auto logout if 401 response returned from api
//            logout();
//            location.reload(true);
//        }

//        const error = (data && data.message) || response.statusText;
//        return Promise.reject(error);
//    }

//return response.data;
//});
//}
