import { titularService } from '../_services';
import { router } from '../_helpers';

let service = titularService;

const state = {
    all: {},
    item: {},
    index: -1,
    isLoading: false,
    defaultTitular: {
        id: 0,
        nombre: '',
        apellido: '',
        direccion: '',
        localidadId: 0,
        provinciaId: 0,
        tipoContribuyenteId: 0,
        tipoPersonaId: 0,
        cuit: '',
        telefono: '',
        celular: '',
        email: '',
        localidadId: 0,
        provincia: { nombre: '', id: 0 },
        localidad: { nombre: '', id: 0 },
        tipoContribuyente: { nombre: 'Consumidor Final', id: 1 },
        tipoPersona: { nombre: 'Persona Física', id: 1 },
        fullName: ''
    }
};

const actions = {
    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    async getAutocomplete({ dispatch, commit, state }, obj) {
        commit('getAutocompleteRequest');
        if (obj != undefined) {
            service.getAutocomplete(obj).then(
                registro => commit('getAutocompleteSuccess', registro),
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );
        }
    },

    getById({ commit }, id) {
        commit('getByRequest');

        service
            .getById(id)
            .then(registro => commit('getBySuccess', registro))
            .catch(error => {
                commit('failure', error);
            })
            .finally(function () { });
    },
    //getxyyyy({ commit }, provId) {
    //    commit('getAllRequest');

    //    service
    //        .getxyyyy(provId)
    //        .then(
    //            registro => commit('getAllSuccess', registro),
    //            error => commit('failure', error)
    //        );
    //},
    postObject({ dispatch, commit, state }, obj) {
        commit('postRequest', obj);

        return service.postObject(obj).then(
            registro => {
                commit('postSuccess', registro);
                return registro;
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    deleteObject({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            user => commit('deleteSuccess', id),
            error => commit('deleteFailure', { id, error: error.toString() })
        );
    },
    putObject({ dispatch, commit, state }, obj) {
        commit('putRequest', obj);

        service.putObject(obj).then(
            registro => commit('putSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
            //error => commit('putFailure', { obj, error: error.toString() })
        );
    },
    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setItems({ commit }, payload) {
        commit('setItems', payload);
    },

    setIndex(state, payload) {
        state.index = payload;
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    }
};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.isLoading = true;
    },
    getAllSuccess(state, registro) {
        state.all = { items: registro };
        state.isLoading = false;
    },

    failure(state, error) {
        state.all = { error };
        state.index = -1;
        state.isLoading = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.isLoading = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.all = {
            items: [{ id: registro.id, fullName: registro.fullName }]
        }
        state.index = registro.id;
        state.isLoading = false;
    },

    // GetAutocomplete
    getAutocompleteRequest(state) {
        state.isLoading = true;
    },
    getAutocompleteSuccess(state, registro) {
        state.all = { items: registro };
        state.isLoading = false;
    },

    // Post
    postRequest(state) {
        state.isLoading = true;
    },
    postSuccess(state, registro) {
        state.isLoading = false;
    },

    // Put
    putRequest(state) {
        state.isLoading = true;
    },
    putSuccess(state, registro) {
        state.isLoading = false;
    },

    deleteRequest(state, id) {
        state.isLoading = true;
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(user => user.id !== id);
        state.isLoading = false;
    },
    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.all.items = state.items.map(user => {
            if (user.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...userCopy } = user;
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error };
            }

            return user;
        });
        state.isLoading = false;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.index = -1;
        }
    },

    setItems(state, payload) {
        state.all.items = payload;
        if (payload.id == 0) {
            state.index = -1;
        }
    },

    pushChild(/*state, payload*/) {
        //state.item.casoTramite.push(payload);
    }
};

export const titular = {
    namespaced: true,
    state,
    actions,
    mutations
};
