import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'api/deposito/';

export const depositoService = {
    getAll,
    getById,
    putObject,
    postObject,
    delete: _delete
};

// Lee todos los elementos
function getAll() {
    let s = authHeader();
    return axios.get(urlAPI + API, s, authHeader()).then(registro => {
        return registro.data;
    });
}

function getById(id) {
    let s = authHeader();
    return axios.get(urlAPI + API + id, s, authHeader()).then(registro => {
        return registro.data;
    });
}

// Post de la entidad
function postObject(obj) {
    let s = authHeader();
    // Copiar todas las propiedades menos id
    var modelo = {
        nombre: obj.nombre,
        direccion: obj.direccion,
        capacidad:obj.capacidad,
        localidadId: obj.localidad.id,
        provinciaId: obj.provincia.id,
        abrv: obj.abrv,
        borrado: obj.borrado
    };
    return axios.post(urlAPI + API, modelo, s, authHeader()).then(registro => {
        // Completa nombre e id de la relacion en select
        registro.data.provincia = {
            id: obj.provincia.id,
            nombre: obj.provincia.nombre
        };
        // Completa nombre e id de la relacion en select
        registro.data.localidad = {
            id: obj.localidad.id,
            nombre: obj.localidad.nombre
        };
      return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var modelo = {
        id: obj.id,
        nombre: obj.nombre,
        direccion: obj.direccion,
        capacidad: obj.capacidad,
        localidadId: obj.localidad.id,
        provinciaId: obj.provincia.id,
        abrv: obj.abrv,
        borrado: obj.borrado
   };
    return axios
        .put(urlAPI + API + modelo.id, modelo, authHeader())
        .then(registro => {
            // Completa nombre e id de la relacion en select
            registro.data.provincia = {
                id: obj.provincia.id,
                nombre: obj.provincia.nombre
            };
            // Completa nombre e id de la relacion en select
            registro.data.localidad = {
                id: obj.localidad.id,
                nombre: obj.localidad.nombre
            };
            return registro.data;
        });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(/*id*/) {
    //const requestOptions = {
    //    method: 'DELETE',
    //    headers: authHeader()
    //};
    //return fetch(`${config.apiUrl}/depositos/${id}`, requestOptions).then(handleResponse);
}

//function handleResponse(response) {
//return response.then(text => {
//    const data = text && JSON.parse(text);
//    if (!response.status === 200) {
//        if (response.status === 401) {
//            // auto logout if 401 response returned from api
//            logout();
//            location.reload(true);
//        }

//        const error = (data && data.message) || response.statusText;
//        return Promise.reject(error);
//    }

//return response.data;
//});
//}
