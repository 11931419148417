import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'api/localidad/';

// Exporta metodos
export const localidadService = {
    getAll,
    getxprovincia,
    getById,
    putObject,
    postObject,
    deleteObject
};

// Lee todos los elementos
function getAll() {
    return axios.get(urlAPI + API, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee solo los elementos relacionados con el id de la entidad padre provincia
function getxprovincia(id) {
    return axios
        .get(urlAPI + API + 'provincia/' + id, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// Lee un solo elemento de la entidad
function getById(id) {
    return axios.get(urlAPI + API + id, authHeader()).then(registro => {
        return registro.data;
    });
}

// Post de la entidad
function postObject(obj) {
    // Copiar todas las propiedades menos id
    var modelo = {
        nombre: obj.nombre,
        provinciaId: obj.provincia.id == 0 ? null : obj.provincia.id,
        borrado: obj.borrado
    };
    return axios.post(urlAPI + API, modelo, authHeader()).then(registro => {
        // Completa nombre e id de la relacion en select
        registro.data.provincia = {
            id: obj.provincia.id,
            nombre: obj.provincia.nombre
        };
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var modelo = {
        id: obj.id,
        nombre: obj.nombre,
        provinciaId: obj.provincia.id,
        borrado: obj.borrado
    };
    return axios
        .put(urlAPI + API + modelo.id, modelo, authHeader())
        .then(registro => {
            // Completa nombre e id de la relacion en select
            registro.data.provincia = {
                id: obj.provincia.id,
                nombre: obj.provincia.nombre
            };
            return registro.data;
        });
}

// delete de la entidad
function deleteObject(obj) {
    return axios.delete(urlAPI + API + obj, authHeader()).then(registro => {
        return registro.data;
    });
}
