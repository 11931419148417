import { estadoService } from '../_services';
import { router } from '../_helpers';

let service = estadoService;

const state = {
    all: {},
    item: { id: 1, nombre: '', textColor: 'black', color: 'white' },
    index: -1,
    progress: false,
    defaultItem: {
        id: 0,
        nombre: '',
        color: '',
        textColor: '',
        estadoTramite: [],
        modulo: 0
    }
};

const getters = {
    // ...
    getEstadoById: (state) => (id) => {
        if (state.item.id != id) {
            state.item = state.all.items.find(todo => todo.id === id);
        }
        return state.item;
        //return state.all.items.find(todo => todo.id === id);
    }
};

const actions = {
    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    async getAllAsync({ dispatch, commit, state }) {
        commit('getAllRequest');
        try {
            var ret = await service.getAllAsync();
            commit('getAllSuccess', ret.data);
        }
        catch (error) {
            commit('failure', error);
            if (error.response.status == 401) {
                dispatch(
                    'alert/errorTime',
                    'Error de permisos o sesión expirada!',
                    {
                        root: true
                    }
                );
                router.push({ name: 'login' });
            } else {
                dispatch('alert/errorTime', error.message, {
                    root: true
                });
            }
        }
    },

    getById({ commit }, id) {
        commit('getByRequest');

        service
            .getById(id)
            .then(registro => commit('getBySuccess', registro))
            .catch(error => commit('failure', error))
            .finally(function () {
                // always executed
            });
    },

    setColor({ commit }, color) {
        commit('setColor', color);
    },

    setTextColor({ commit }, color) {
        commit('setTextColor', color);
    },

    setDefault({ commit }, item) {
        commit('setDefault', item);
    },

    changePosition({ commit }, payload) {
        commit('changePosition', payload);
    },

    //getxyyyy({ commit }, provId) {
    //    commit('getAllRequest');

    //    service
    //        .getxyyyy(provId)
    //        .then(
    //            registro => commit('getAllSuccess', registro),
    //            error => commit('failure', error)
    //        );
    //},
    postObject({ dispatch, commit, state }, obj) {
        commit('postRequest', obj);

        return service.postObject(obj).then(
            registro => {
                commit('postSuccess', registro);
                return registro;
            },
            error =>
                commit('failure', {
                    obj,
                    error: error.response.data.errors
                })
        );
    },

    deleteObject({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            () => commit('deleteSuccess', id),
            error => commit('failure', { id, error: error.toString() })
        );
    },
    putObject({ dispatch, commit, state }, obj) {
        commit('putRequest', obj);

        service.putObject(obj).then(
            registro => commit('putSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
            //error => commit('failure', { obj, error: error.toString() })
        );
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setIndex({ commit }, payload) {
        commit('setIndex', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    }
};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.all = { loading: true };
        state.progress = true;
    },
    getAllSuccess(state, registro) {
        state.all = { items: registro };
        state.progress = false;
    },
    failure(state, error) {
        state.all = { error };
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.progress = false;
    },

    setColor(state, registro) {
        state.item.color = registro;
    },

    setTextColor(state, registro) {
        state.item.textColor = registro;
    },

    setDefault(state, registro) {
        //if (registro.default == true) {
        //    state.item.estadoTramite.find(function(reg) {
        //        if (registro != reg) {
        //            reg.default = false;
        //        }
        //    });
        //}
    },

    changePosition(state, payload) {
        let orden = payload.item.orden;
        let inc = payload.inc;

        state.item.estadoTramite.find(function (reg) {
            if (inc > 0) {
                if (reg.orden == orden) {
                    reg.orden--;
                }
            } else {
                if (reg.orden == orden) {
                    reg.orden++;
                }
            }
        });
        payload.item.orden += inc;

        //commit('setDefault', { item, incrementa });
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },
    postSuccess(state, registro) {
        state.item = registro;
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },
    putSuccess(state, registro) {
        //    state.item = registro;
        state.progress = false;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(user => user.id !== id);
        state.progress = false;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.index = -1;
        }
    },

    setIndex(state, payload) {
        state.index = payload;
    },

    pushChild(/*state, payload*/) {
        //state.item.casoTramite.push(payload);
    }
};

export const estado = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
