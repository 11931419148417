export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {
            headers: {
                Authorization: 'Bearer ' + user.token,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.token
            }
        };
    } else {
        return {};
    }
}

export function token() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    let tk = 'Bearer ' + user.token;

    if (user && user.token) {
        return tk;
    } else {
        return {};
    }
}
