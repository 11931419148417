import { modelovalorService } from '../_services';
import { router } from '../_helpers';

let service = modelovalorService;

const state = {
    filas: [],
    item: {},
    modelovalorDTO: [],
    dialog: false,
    progress: false,
    processed: false,
    resultadosDTO: {},
    error: false,
};

const actions = {
    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesi�n expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    async getById({ dispatch, commit }, id) {
        commit('getByRequest');

        await service.getById(id).then(
            registro => commit('getBySuccess', registro),
            error =>
                dispatch('alert/errorTime', error.message, {
                    root: true
                })
        );
    },

    async getByMarcaModeloTipo({ dispatch, commit }, payload) {
        try {
            commit('getByRequest');

            let registro = await service.getByMarcaModeloTipo(payload)
            commit('getBySuccess', registro);
        }
        catch (error) {
            dispatch('alert/errorTime', error.message, {
                root: true
            })

        }
    },

    postObject({ dispatch, commit }) {
        commit('postRequest');

        return service.postObject(state.modelovalorDTO).then(
            registro => commit('postSuccess', registro),
            error => {
                commit('failure', error);
                dispatch('alert/error', error.response.data, { root: true })
            }
        );
    },

    pushFilas({ commit }, payload) {
        commit('pushFilas', payload);
    },

    setFilas({ commit }, payload) {
        commit('setFilas', payload);
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },

};

const mutations = {
    failure(state, error) {
        state.id = 0;
        state.progress = false;
    },

    // GetAll
    getAllRequest(state) {
        state.items = [];
        state.progress = true;
    },
    getAllSuccess(state, registro) {
        state.items = registro;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.id = registro.id;
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
        state.modelovalorDTO = state.filas.map(item => {
            let itm = {
                modeloValorId: item.MTMFMM,
                in: item.IN,
                t: item.T,
                fabId: item.Fab,
                marcaId: item.Marca,
                tipoId: item.Tipo,
                modId: item.Mod,
                marca: item.Descmarca,
                tipo: item.DescTipo,
                modelo: item.DescModelo,
                tipVehiculoId: null,
                tipVehiculo: null,
                modeloValorAnioDTO: []
            }
            //state.filas.forEach(item => {
            if (item != null) {
                var x;
                for (x in item) {
                    var n = Number(x);
                    if ((Number.isNaN(n) == false || (Number.isNaN(n) == true && x == "0Km"))
                        && (x == "0Km" || (n > 1950 && n < 2100))) {
                        if (item[x] != "")
                            itm.modeloValorAnioDTO.push({ anio: x, valor: item[x] })
                        //console.log(item[x]);
                    }
                }
                //}
            }
            return itm;
        });
    },

    postSuccess(state, registro) {
        state.filas = [];
        state.resultadosDTO = registro,
            state.dialog = true,
            state.progress = false;
    },

    pushFilas(state, payload) {
        state.filas.push(payload);
    },

    setFilas(state, payload) {
        state.filas = payload;
    },

    setDialog(state, payload) {
        state.dialog = payload;
    },

};

export const modelovalor = {
    namespaced: true,
    state,
    actions,
    mutations
};
