<template>
    <v-data-table
        :headers="headers"
        dense
        :items="all.items"
        sort-by="id"
        :search="search"
        class="elevation-1"
        @click:row="editItem"
    >
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Titular</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                >
                </v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="warning"
                            v-on:click="editItem(null)"
                        >
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <titularDialogo @closeDlg="close"></titularDialogo>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import titularDialogo from '@/components/Titular/TitularDialogo';

export default {
    name: 'titular',
    data() {
        return {
            dialog: false,
            search: '',
            headers: [
                {
                    text: 'Id',
                    align: 'left',
                    sortable: true,
                    value: 'id'
                },
                { text: 'Apellido', value: 'apellido' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Dirección', value: 'direccion' },
                { text: 'Celular', value: 'celular' },
                { text: 'Teléfono', value: 'telefono' },
                { text: 'e-mail', value: 'email' },
                { text: 'Opciones', value: 'action', sortable: false }
            ]
        };
    },

    computed: {
        ...mapState('titular', ['all', 'item', 'defaultTitular'])
    },

    watch: {
        dialog(val) {
            val || this.close();
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        ...mapActions('titular', ['getAll', 'getById', 'setItem']),

        close() {
            this.dialog = false;
            this.getAll();
        },

        initialize() {
            this.getAll();
            this.setItem(Object.assign({}, this.defaultItem));
        },

        editItem(itm) {
            if (itm === null)
                this.setItem(Object.assign({}, this.defaultTitular));
            else {
                this.getById(itm.id);
            }
            this.dialog = true;
        },

        deleteItem(item) {
            const index = this.all.items.indexOf(item);
            if (confirm('Desea borrar el caso?')) {
                this.deleteObject(item.id);
                this.all.items.splice(index, 1);
            }
        }
    },
    components: {
        titularDialogo
    }
};
</script>
