<template>
    <v-layout row wrap class="pa-1 mr-1">
        <v-menu v-model="fromDateMenu"
                :disabled="disabled"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field :label="label"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              :value="fromDateDisp"
                              v-on="on">
                </v-text-field>
            </template>
            <!--:min="minDate"
            :max="maxDate"-->
            <v-date-picker :disabled="disabled"
                           locale="es-ar"
                           v-model="value"
                           no-title
                           @input="close"></v-date-picker>
        </v-menu>
    </v-layout>
</template>

<script>
    export default {
        name: 'fecha',
        props: ['value', 'label', 'disabled'],
        data() {
            return {
                fromDateMenu: false,

                minDate: '2019-07-04',
                maxDate: '2019-08-30'
            };
        },
        computed: {
            fromDateDisp() {
                if (!this.value) return null;
                var str =
                    this.value.substring(8, 10) +
                    '/' +
                    this.value.substring(5, 7) +
                    '/' +
                    this.value.substring(0, 4);
                return str;
                // format date, apply validations, etc. Example below.
                // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
            }
        },
        methods: {
            close() {
                this.fromDateMenu = false;
                this.$emit('input', this.value);
                this.$emit('change', this.value);
            }
        }
    };
</script>
