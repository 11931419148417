import { accountService } from '../_services';
import { router } from '../_helpers/router.js';

let service = accountService;

const state = {
    loggedUser: {},
    progress: false,
    processed: false,
    error: false,
    confirmado: true,
    registrado: false,
    permisos: {},
    grants: {},
    defaultUserMenu: [
        {
            title: "Ingresa",
            ruta: "/Identity/Login",
            icon: "mdi-account-multiple",
            loggedIn: false,
            tooltip: "Inicia sesión"
        },
        {
            title: "Sale",
            ruta: "/Identity/Logout",
            icon: "mdi-account-multiple",
            loggedIn: true,
            logout: true,
            tooltip: "Termina sesión"
        },
    ],
    defaultUser: {
        userName: "",
        avatar: "",
        loggedIn: false,
        userTypeId: 0,
        userType: "",
        grants: [],

        menu: [
            {
                title: "Ingresa",
                ruta: "/Identity/Login",
                icon: "mdi-account-multiple",
                loggedIn: false,
                tooltip: "Inicia sesión"
            },
            {
                title: "Sale",
                ruta: "/Identity/Logout",
                icon: "mdi-account-multiple",
                loggedIn: true,
                logout: true,
                tooltip: "Termina sesión"
            },
        ]
    },
    menu: [
        //{
        //    title: "Home",
        //    ruta: "/",
        //    icon: "mdi-home-outline",
        //    // show: false,  Solo poner en false cuando no debe salir
        //    loggedIn: true,
        //    tooltip: "Caso"
        //},
        {
            title: "Bajas",
            ruta: "/CasoCrud",
            icon: "mdi-thumb-down-outline",
            // show: false,  Solo poner en false cuando no debe salir
            loggedIn: true,
            tooltip: "Bajas",
            auth: "0100"
        },
        {
            title: "Guarda",
            ruta: "/Guarda",
            icon: "mdi-buffer",
            // show: false,  Solo poner en false cuando no debe salir
            loggedIn: true,
            tooltip: "Casos en guarda",
            auth: "0700",
        },
        {
            title: "Entidades",
            ruta: "",
            icon: "mdi-group",
            loggedIn: true,
            tooltip: "ABM de entidades",
            auth: "0200",
            subMenu: [
                {
                    title: "Gestor",
                    ruta: "/Gestor",
                    icon: "mdi-run-fast",
                    loggedIn: true,
                    tooltip: "Gestores"
                },
                {
                    title: "Productor",
                    ruta: "/Productor",
                    icon: "mdi-briefcase",
                    loggedIn: true,
                    tooltip: "Productores"
                },

                {
                    title: "Recuperador",
                    ruta: "/Recuperador",
                    icon: "mdi-car-connected",
                    loggedIn: true,
                    tooltip: "Recuperadores"
                },

                {
                    title: "Transportista",
                    ruta: "/Transportista",
                    icon: "mdi-human",
                    loggedIn: true,
                    tooltip: "Transportistas"
                },

                {
                    title: "Registro",
                    ruta: "/Registro",
                    icon: "mdi-book-open-page-variant",
                    loggedIn: true,
                    tooltip: "Registros del automotor"
                },

                {
                    title: "Titular",
                    ruta: "/Titular",
                    icon: "mdi-account",
                    loggedIn: true,
                    tooltip: "Titular"
                },

                {
                    title: "Grua",
                    ruta: "/Grua",
                    icon: "mdi-car-pickup",
                    loggedIn: true,
                    tooltip: "Datos de gruas"
                },

                {
                    title: "Notificaciones",
                    ruta: "/Notificacion",
                    icon: "mdi-send",
                    loggedIn: true,
                    tooltip: "Estado de notificaciones"
                },

            ]
        },
        {
            title: "Tablas",
            ruta: "",
            icon: "mdi-checkbox-multiple-blank-outline",
            loggedIn: true,
            tooltip: "ABM de tablas",
            auth: "0300",
            subMenu: [
                {
                    title: "Cliente",
                    ruta: "/Cliente",
                    icon: "mdi-group",
                    loggedIn: true,
                    tooltip: "Clientes"
                },

                {
                    title: "Desarmadero",
                    ruta: "/Desarmadero",
                    icon: "mdi-wrench",
                    loggedIn: true,
                    tooltip: "Desarmaderos"
                },

                {
                    title: "Localidad",
                    ruta: "/Localidad",
                    icon: "mdi-domain",
                    loggedIn: true,
                    tooltip: "Localidades"
                },

                {
                    title: "Marca",
                    ruta: "/Marca",
                    icon: "mdi-chevron-double-up",
                    loggedIn: true,
                    tooltip: "Marcas"
                },

                {
                    title: "Modelo",
                    ruta: "/Modelo",
                    icon: "mdi-server-security",
                    loggedIn: true,
                    tooltip: "Modelo"
                },

                {
                    title: "Provincia",
                    ruta: "/Provincia",
                    icon: "mdi-map-marker",
                    loggedIn: true,
                    tooltip: "Provincias"
                },

                {
                    title: "Tramitador",
                    ruta: "/Tramitador",
                    icon: "mdi-walk",
                    loggedIn: true,
                    tooltip: "Tramitadores"
                },

                {
                    title: "Zonainfluencia",
                    ruta: "/zonainfluencia",
                    icon: "mdi-web",
                    loggedIn: true,
                    tooltip: "Zonas de Influencia"
                }
            ]
        },
        {
            title: "Parámetros",
            ruta: "",
            icon: "mdi-layers-off",
            loggedIn: true,
            tooltip: "ABM de parámetros",
            auth: "0400",
            subMenu: [
                {
                    title: "Estado",
                    ruta: "/Estado",
                    icon: "mdi-camera-timer",
                    loggedIn: true,
                    tooltip: "Estados de tarea"
                },

                {
                    title: "Estados de informe",
                    ruta: "/EstadoInforme",
                    icon: "mdi-clipboard-check-outline",
                    loggedIn: true,
                    tooltip: "Estados de informe"
                },

                {
                    title: "T. Baja",
                    ruta: "/TipoBaja",
                    icon: "mdi-walk",
                    loggedIn: true,
                    tooltip: "Tipos de Baja"
                },

                {
                    title: "TipVehiculo",
                    ruta: "/TipVehiculo",
                    icon: "mdi-car-estate",
                    loggedIn: true,
                    tooltip: "Tipos de vehiculo"
                },

                {
                    title: "Tramite",
                    ruta: "/Tramite",
                    icon: "mdi-format-align-justify",
                    loggedIn: true,
                    tooltip: "Trámites"
                },

                {
                    title: "Exporta",
                    ruta: "/ExportaCasos",
                    icon: "mdi-file-excel",
                    loggedIn: true,
                    tooltip: "Exporta casos a Excel"
                },

                {
                    title: "Importa Valores de modelo",
                    ruta: "/ImportaModeloValor",
                    icon: "mdi-file-pdf",
                    loggedIn: true,
                    tooltip: "Importa valores de costo de modelos"
                },

                {
                    title: "Tipos de vehiculo en modelos valor",
                    ruta: "/ModeloValorTipo",
                    icon: "mdi-tag-multiple",
                    loggedIn: true,
                    tooltip: "Asocia tipos en modelos"
                },

                {
                    title: "Depósito",
                    ruta: "/Deposito",
                    icon: "mdi-factory",
                    loggedIn: true,
                    tooltip: "Carga de depósitos"
                },

            ]
        },
        {
            title: "",
            ruta: "",
            icon: "mdi-dots-vertical",
            loggedIn: true,
            tooltip: "ABM de tablas de administrador",
            auth: "0500",
            subMenu: [
                {
                    title: "Usuarios",
                    ruta: "/Usuario",
                    icon: "mdi-account-multiple",
                    loggedIn: true,
                    tooltip: "Configuracón de usuarios"
                },
                {
                    title: "Roles",
                    ruta: "/Role",
                    icon: "mdi-account-multiple",
                    loggedIn: true,
                    tooltip: "Configuracón de roles"
                },
                {
                    title: "Campos de tramite",
                    ruta: "/CampoTramite",
                    icon: "mdi-account-multiple",
                    loggedIn: true,
                    tooltip: "Configuración de campos de trámites"
                },
                {
                    title: "System Data",
                    ruta: "/SystemData",
                    icon: "mdi-account-multiple",
                    loggedIn: true,
                    tooltip: "Variables del sistema"
                }
            ]
        }
    ]

};

const actions = {
    checkUser({ state, commit }) {
        state.loggedUser = JSON.parse(localStorage.getItem('user'));
        if (state.loggedUser != null) {
            let t = new Date(state.loggedUser.expiration);
            let u = new Date();
            if (u > t) {
                state.loggedUser = null;
                localStorage.removeItem('user');
            }
        }
        if (state.loggedUser == null)
            state.loggedUser = JSON.parse(JSON.stringify(state.defaultUser));
        else {
            commit('setPermisos', service.setPermisos(state.loggedUser));
            commit('setGrants', service.setGrants(state.loggedUser));
            commit('setDefaultUserMenu');

            state.loggedUser.loggedIn = true;
        }
    },

    login({ dispatch, commit, state }, { username, password }) {
        commit('loginRequest', { username });
        const self = router;

        service.login(username, password).then(
            user => {
                const us = JSON.parse(JSON.stringify(user));
                commit('loginSuccess', us);
                commit('setPermisos', service.setPermisos(us));
                commit('setGrants', service.setGrants(us));
                commit('setDefaultUserMenu');
                self.push('/');
            },
            error => {
                commit('loginFailure', error);
                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    },

    logout(context) {
        service.logout();
        context.commit('logout');
        // Redirige a otro sitio web
        window.location.href = 'https://tf3040.com.ar';
        //       self.push('/');
    },

    register({ dispatch, commit }, user) {
        commit('registerRequest', user);

        service.register(user).then(
            user => {
                commit('registerSuccess', user);
                setTimeout(() => {
                    // display success message after route change completes
                    dispatch('alert/success', 'Registrado correctamente', {
                        root: true
                    });
                });
            },
            error => {
                commit('registerFailure', error);

                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    },

    forgot({ dispatch, commit }, email) {
        commit('forgotRequest', email);

        service.forgot(email).then(
            user => {
                commit('forgotSuccess', user);
                setTimeout(() => {
                    // display success message after route change completes
                    dispatch('alert/success', 'Correo enviado', {
                        root: true
                    });
                });
            },
            error => {
                commit('forgotFailure', error);

                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    },

    reset({ dispatch, commit }, payload) {
        commit('resetRequest', payload);

        service.reset(payload).then(
            () => {
                commit('resetSuccess');
                setTimeout(() => {
                    dispatch(
                        'alert/successTime',
                        {
                            message: 'Cambio de contraseña Confirmado',
                            timeout: 2000
                        },
                        {
                            root: true
                        }
                    );
                    router.push('/Identity/Login');
                });
            },
            error => {
                commit('resetFailure', error);

                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    },

    resetSc({ dispatch, commit }, payload) {
        commit('resetRequest', payload);

        service.resetSc(payload).then(
            () => {
                commit('resetSuccess');
                setTimeout(() => {
                    dispatch(
                        'alert/successTime',
                        {
                            message: 'Cambio de contraseña Confirmado',
                            timeout: 2000
                        },
                        {
                            root: true
                        }
                    );
                    router.push('/Identity/Login');
                });
            },
            error => {
                commit('resetFailure', error);

                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    },

    confirm({ dispatch, commit }, { userId, code }) {
        commit('confirmRequest', userId);

        service.confirma({ userId, code }).then(
            user => {
                commit('confirmSuccess', user);
                // display success message after route change completes
                dispatch('alert/successTime', 'Confirmado', {
                    root: true
                });
                router.push('/Identity/Login');
            },
            error => {
                commit('confirmFailure', error);
                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    // 'Código de confirmación o id inválido',
                    root: true
                });
            }
        );
    },

    ResendConfirmationEmail({ dispatch, commit }, mail) {
        commit('ResendConfirmationEmailRequest', mail);

        service.ResendConfirmationEmail(mail).then(
            user => {
                commit('ResendConfirmationEmailSuccess', user);
                setTimeout(() => {
                    // display success message after route change completes
                    dispatch('alert/success', 'EMail enviado!', {
                        root: true
                    });
                });
            },
            error => {
                commit('ResendConfirmationEmailFailure', error);
                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    }
};

const getters = {

    error() {
        return state.error;
    }
};

const mutations = {
    reset(state) {
        state.progress = false;
        state.processed = false;
        state.error = false;
    },

    loginRequest(state) {
        state.loggedUser.loggedIn = false;
        state.error = false;
        state.progress = true;
        state.processed = false;
    },
    loginSuccess(state, user) {
        state.loggedUser = user;
        state.loggedUser.loggedIn = true;
        state.progress = false;
        state.processed = true;
        state.confirmado = true;
    },
    loginFailure(state) {
        state.loggedUser = JSON.parse(JSON.stringify(state.defaultUser));
        state.error = true;
        state.progress = false;
        state.processed = true;
        state.confirmado = false;
    },
    setPermisos(state, permisos) {
        state.permisos = permisos;
    },

    setGrants(state, grants) {
        state.loggedUser.grants = grants;
    },

    setDefaultUserMenu(state) {
        state.loggedUser.menu = state.defaultUserMenu;
    },

    logout(state) {
        state.loggedUser = JSON.parse(JSON.stringify(state.defaultUser));
        state.progress = false;
        state.processed = true;
    },

    registerRequest(state /*, user*/) {
        state.status = { registering: true };
        state.error = false;
        state.progress = true;
        state.processed = false;
        state.registrado = false;
    },

    registerSuccess(state /*, user*/) {
        state.status = {};
        state.progress = false;
        state.processed = true;
        state.registrado = true;
    },
    registerFailure(state /*, dispatch, error*/) {
        state.status = {};
        state.error = true;
        state.progress = false;
        state.processed = true;
    },

    forgotRequest(state /*, user*/) {
        state.status = { registering: true };
        state.error = false;
        state.progress = true;
        state.processed = false;
    },

    forgotSuccess(state /*, user*/) {
        state.status = {};
        state.progress = false;
        state.processed = true;
    },
    forgotFailure(state /*, dispatch, error*/) {
        state.status = {};
        state.error = true;
        state.progress = false;
        state.processed = true;
    },

    resetRequest(state /*, user*/) {
        state.status = { reseting: true };
        state.error = false;
        state.progress = true;
        state.processed = false;
    },
    resetSuccess(state /*, user*/) {
        state.status = {};
        state.progress = false;
        state.processed = true;
    },
    resetFailure(state /*, dispatch, error*/) {
        state.status = {};
        state.error = true;
        state.progress = false;
        state.processed = true;
    },

    confirmRequest(state /*, user*/) {
        state.status = { registering: true };
        state.progress = true;
        state.processed = false;
    },
    confirmSuccess(state /*, user*/) {
        state.status = {};
        state.progress = false;
        state.processed = true;
    },
    confirmFailure(state /*, error*/) {
        state.status = {};
        state.progress = false;
        state.processed = true;
    },

    ResendConfirmationEmailRequest(state /*, user*/) {
        state.status = { registering: true };
        state.progress = true;
        state.processed = false;
    },
    ResendConfirmationEmailSuccess(state /*, user*/) {
        state.status = {};
        state.progress = false;
        state.processed = true;
    },
    ResendConfirmationEmailFailure(state /*, error*/) {
        state.status = {};
        state.progress = false;
        state.processed = true;
    }

    //forgot(state, email) {
    //    axios
    //        .get(`https://localhost:44368/identity/Account/forgot/` + email)
    //        .then(response => {
    //            state.loggedIn = false;
    //            state.user = '';
    //            // store user details and jwt token in local storage to keep user logged in between page refreshes
    //            return response;
    //        })
    //        .catch(error => {
    //            return error;
    //        });
    //},
};

export const account = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
