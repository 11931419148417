<template>
    <v-card class="mx-auto mt-10" max-width="1000px">
        <v-card-title class="pa-0">
            <v-toolbar flat color="yellow">
                <v-btn text :disabled="progress"
                       @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    <h4>Importa valores de modelos</h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="textArea!=''" dark
                       rounded
                       small
                       color="error"
                       @click="upload">
                    <v-icon>mdi-upload</v-icon>
                    Convierte
                </v-btn>
                <v-badge v-if="filas.length!=0"
                         bordered
                         color="primary"
                         :content="filas.length"
                         overlap
                         class="mr-10">
                    <v-btn :dark="!progress"
                           rounded
                           small
                           color="error"
                           @click="importa"
                           :disabled="progress">
                        <v-icon>mdi-database-plus</v-icon>
                        Graba
                        <span>
                            <v-progress-circular class="pa-3" indeterminate
                                                 v-if="progress">
                            </v-progress-circular>
                        </span>
                    </v-btn>
                </v-badge>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-textarea label="pegue la tabla excel de valores de modelos"
                        clearable
                        :rows="textArea==''?1:20"
                        clear-icon="mdi-delete-sweep"
                        v-model="textArea"
                        @input="changeTextArea">
            </v-textarea>
            <v-data-table :headers="titulos"
                          :items="filas"
                          class="elevation-1">
            </v-data-table>
            <v-progress-linear indeterminate
                               :active="progress"
                               color="yellow darken-2"></v-progress-linear>
        </v-card-text>
        <div hidden id="datos" v-html="tableHtml"><img src="" alt="Importa lista desde excel" /></div>
        <v-dialog v-model="propDialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">Resultados del proceso</span>
                </v-card-title>

                <v-card-text>
                    <v-container grid-list-md>
                        <v-row>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <h3>Enviadas: {{resultadosDTO.enviadas}}</h3>
                                </v-flex>
                                <v-flex xs12>
                                    <h3>Procesadas: {{resultadosDTO.procesadas}}</h3>
                                </v-flex>
                                <v-flex xs12>
                                    <h3>Agregadas: {{resultadosDTO.agregadas}}</h3>
                                </v-flex>
                                <v-flex xs12>
                                    <h3>Actualizadas: {{resultadosDTO.actualizadas}}</h3>
                                </v-flex>
                                <v-flex xs12>
                                    <h3>Tipos Aregados: {{resultadosDTO.tiposNuevos}}</h3>
                                </v-flex>
                                <v-flex v-if="resultadosDTO.error!=null" xs12>
                                    <h3>Error: {{resultadosDTO.error}}</h3>
                                </v-flex>
                            </v-layout>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="propDialog=false">Cierra</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { router } from '../_helpers';

    export default {
        name: 'ImportaModeloValor',
        data() {
            return {
                titulos: [],
                textArea: '',
                tableArea: []
            };
        },
        methods: {
            ...mapActions('modelovalor', ['postObject', 'setFilas', 'pushFilas', 'setDialog']),

            closeDialog() {
                router.push({ name: 'home' });
            },

            importa() {
                this.postObject();
            },

            changeTextArea() {
                this.setFilas([]);
                this.titulos = [];
                let tex = '<table class="table-hover table-bordered text-center" id="lista" > <tbody><tr><td>' +
                    this.textArea.
                        replace(/\nI\t/gi, '</tr><tr><td>I</td><td>').
                        replace(/\nN\t/gi, '</tr><tr><td>N</td><td>').
                        replace(/\n/g, '').
                        replace(/\t/g, '</td><td>') +
                    '</tr></tbody></table>';

                this.tableArea = tex;
            },

            upload() {
                let rows = document.getElementById("lista").rows;

                for (let x = 0; x < rows.item(0).cells.length; x++) {
                    let t = rows.item(0).cells[x].innerText;

                    this.titulos.push({ text: t, value: t.replace(/ /g, "").replace(/\./g, "").replace(/\//g, "") });
                }

                for (let i = 1; i < rows.length; i++) {
                    let str = '{';
                    for (let j = 0; j < this.titulos.length; j++) {
                        str = str + `"${this.titulos[j].value}":"${rows.item(i).cells[j].innerText}"${this.titulos.length == j + 1 ? "}" : ","}`;
                    }
                    this.pushFilas(JSON.parse(str));
                }

                this.textArea = '';
                this.tableArea = [];
            }
        },

        computed: {
            ...mapState('modelovalor', ['progress', 'filas', 'resultadosDTO', 'dialog']),

            countCrudos() {
                let r = document.getElementById("lista");
                if (r == null)
                    return 0;
                return document.getElementById("lista").tBodies[0].rows.length;
            },

            tableHtml() {
                return this.tableArea;
            },

            propDialog: {
                get() {
                    return this.dialog;
                },
                set(value) {
                    this.setDialog(value);
                }
            },

        }
    };
</script>
                // /\"[a-zA-Z0-9\s()\.\-\/]+\"/gi  'Encuentra las celdas con enter

                //this.tableArea = '[{"' +
                //    this.textArea.
                //        replace(/\"/g, '').
                //        replace(/\nI\t/gi, '"},{"I","').
                //        replace(/\nN\t/gi, '"},{"N","').
                //        replace(/\n/g, '').
                //        replace(/\t/g, '","') +
                //    '}]'
                //let a = JSON.parse(this.tableArea);
                //var items = this.tableArea.map((item) => {
                //    return item;
                //})
