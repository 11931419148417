<template>
  <v-chip :color="config.menuColor" :dark="config.dark == '1' || this.config.dark == 'true'">
      <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-btn v-if="valores.logout!=true" text :to="valores.ruta" v-on="on" class="text-none pa-0">
                  <v-icon class="mr-1">{{ valores.icon }}</v-icon>
                  {{ valores.title }}
              </v-btn>
              <v-btn v-else text @click="$emit('logout')" class="text-none pa-0">
                  <v-icon class="mr-1">{{ valores.icon }}</v-icon>
                  {{ valores.title }}
              </v-btn>
          </template>

          <span>{{ valores.tooltip }}</span>
        </v-tooltip>
  </v-chip>

  <!-- </v-toolbar-items> -->
</template>


<script>
export default {
  name: "navItem",
  props: ["valores","config"],
  data() {
      return {
          dlg: false
      };
  },
  methods: {
    showed() {
      return item.show != false;
    }
  }
};
</script>

<style>
</style>