export * from './alerta.service';
export * from './account.service';
export * from './role.service';
export * from './user.service';
export * from './campotramite.service';
export * from './caso.service';
export * from './cliente.service';
export * from './desarmadero.service';
export * from './deposito.service';
export * from './estado.service';
export * from './estadoinforme.service';
export * from './gestor.service';
export * from './grua.service';
export * from './guarda.service';
export * from './image.service';
export * from './localidad.service';
export * from './marca.service';
export * from './modelo.service';
export * from './modelovalor.service';
export * from './modeloValorTipo.service';
export * from './neDestino.service';
export * from './neNotificacion.service';
export * from './productor.service';
export * from './provincia.service';
export * from './recuperador.service';
export * from './registro.service';
export * from './tipobaja.service';
export * from './tipocontribuyente.service';
export * from './tipopersona.service';
export * from './tipvehiculo.service';
export * from './titular.service';
export * from './tramitador.service';
export * from './tramite.service';
export * from './transportista.service';
export * from './zonainfluencia.service';

export let urlAPI = process.env.VUE_APP_API_ENDPOINT;
export let urlImageAPI = process.env.VUE_APP_IMAGE_ENDPOINT;
