<template>
    <div>
        <v-app-bar :color="config.navColor" :dark="config.dark" dense>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>
                <v-img src="@/assets/Logo3040.png"
                       max-height="40"
                       max-width="40"
                       pl-0></v-img>
            </v-toolbar-title>

            <!--{{new Date(x_user.expiration)}}-->
            <v-spacer></v-spacer>
            <v-toolbar-items v-for="(item, index) in x_menu" :key="index" class="hidden-sm-and-down">
                <navItem v-if="item.subMenu==undefined"
                         class="mt-2 mr-2"
                         :valores="item"
                         :config="config"
                         @logout="$emit('logout')"></navItem>
                <navMenuItem v-else
                             class="mt-2 mr-2"
                             :config="config"
                             :valores="item"
                             @logout="$emit('logout')"></navMenuItem>
            </v-toolbar-items>

            <v-toolbar-items class="hidden-sm-and-down">
                <navUserMenu :user="x_user"
                             :config="config"
                             :menu="x_menuUser"
                             @logout="$emit('logout')"></navUserMenu>
            </v-toolbar-items>
        </v-app-bar>

        <template>
            <v-navigation-drawer app
                                 :color="config.navColor"
                                 :dark="config.dark"
                                 v-model="drawer"
                                 temporary
                                 :mini-variant.sync="mini">
                <v-list dense>
                    <v-list-item v-if="!mini">
                        <v-chip color="white" class="ma-2">
                            <v-btn class="pa-1" icon to="/" width="20" height="20">
                                <v-img src="@/assets/Logo3040.png"></v-img>
                            </v-btn>
                        </v-chip>
                        <v-btn icon @click.stop="mini = !mini">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-list-item>

                    <navUserMenuDrawer :menu="x_menuUser"
                                       :config="config"
                                       :user="x_user"
                                       @logout="$emit('logout')"></navUserMenuDrawer>

                    <v-divider></v-divider>

                    <div v-for="(item, index) in x_menu" :key="index" class="ma-0 pa-0" link>
                        <navDrawerItem v-if="item.subMenu==undefined"
                                       :valores="item"
                                       :config="config"
                                       :user="x_user"
                                       @logout="$emit('logout')"></navDrawerItem>
                        <navMenuDrawerItem v-else
                                           :config="config"
                                           :valores="item"
                                           :user="x_user"
                                           @logout="$emit('logout')"></navMenuDrawerItem>
                    </div>
                </v-list>
            </v-navigation-drawer>
        </template>
    </div>
</template>

<script>
    import navDrawerItem from "@/components/Tools/Navigation/navDrawerItem";
    import navMenuDrawerItem from "@/components/Tools/Navigation/navMenuDrawerItem";
    import navItem from "@/components/Tools/Navigation/navItem";
    import navMenuItem from "@/components/Tools/Navigation/navMenuItem";
    import navUserMenu from "@/components/Tools/Navigation/navUserMenu";
    import navUserMenuDrawer from "@/components/Tools/Navigation/navUserMenuDrawer";

    export default {
        name: "Navigator",

        props: ["menu", "user", "config"],

        data() {
            return {
                appTitle: "TF3040",
                drawer: false,
                mini: false,
                width: 0,
                url: process.env.VUE_APP_TITLE,
                permisos: {
                    configuracion: true,
                    casos: true,
                    parametros: true,
                    entidades: true,
                    tablas: true
                },
                loggedIn: false
            };
        },

        computed: {
            x_menu() {
                if (this.x_user.loggedIn != undefined) {
                    return this.processMenu(this.menu, this.x_user.loggedIn);
                }
                return null;
            },

            x_menuUser() {
                if (this.x_user.loggedIn != undefined) {
                    return this.processMenu(this.user.menu, this.x_user.loggedIn);
                }
                return null;
            },

            x_user() {
                return this.user;
            },

        },

        methods: {
            logout() {
                this.$emit('logout');
            },

            processMenu(menu, loggedIn) {
                let self = this;
                if (menu != undefined) {
                    return menu
                        .map(item => {
                            if (
                                (item.loggedIn == undefined ||
                                    item.loggedIn == loggedIn) &&
                                (item.auth == undefined ||
                                    (item.auth != undefined && self.x_user.grants != undefined &&
                                        self.x_user.grants.indexOf(item.auth) > -1))
                            ) {
                                if (item.subMenu != undefined && item.subMenu.length > 0) {
                                    item.subMenu = self.processMenu(item.subMenu, loggedIn);
                                }
                                return item;
                            }
                        })
                        .filter(x => x != undefined);
                }
            }
        },

        components: {
            navDrawerItem,
            navMenuDrawerItem,
            navItem,
            navMenuItem,
            navUserMenu,
            navUserMenuDrawer
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .v-btn {
        text-transform: none;
    }

    .v-icon {
        padding-right: 4px;
    }
</style>
