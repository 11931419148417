<template>
    <v-app id="app">
        <navigation :user="loggedUser" :menu="menu" :config="config" @logout="logout"></navigation>
        <v-main transition="slide-x-transition" class="mt-1">
            <Alerta></Alerta>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import navigation from '@/components/Tools/Navigation/Navigation';
    import Alerta from '@/components/Alerta';
    import { store } from '@/store/index';
    import { router } from './_helpers/router';

    export default {
        name: 'app',
        data() {
            return {
            };
        },
        computed: {
            ...mapState(['config']),
            ...mapState('account', ['loggedUser', 'menu']),
            ...mapState({ alert: state => state.alert })
        },

        methods: {
            ...mapActions({ clearAlert: 'alert/clear' }),
            ...mapActions('account', ['checkUser','logout']),

        },

        created() {
            this.checkUser();
        },

        watch: {
            $route(/*to, from*/) {
                // clear alert on location change
                this.clearAlert();
            }
        },
        // provide the store using the "store" option.
        // this will inject the store instance to all child components.
        store,
        router,
        components: {
            navigation,
            Alerta
        }
    };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

    #navigation {
        padding: 30px;
    }

        #navigation a {
            font-weight: bold;
            color: #2c3e50;
        }

            #navigation a.router-link-exact-active {
                color: #42b983;
            }

    #v-toolbar {
        color: brown;
    }

    .tituloCrud {
        color: yellow;
    }
</style>
