<template>
    <v-select
        v-model="value"
        :dense="dense"
        :disabled="disabled"
        :items="all.items"
        item-text="nombre"
        item-value="id"
        label="Tipo de persona"
        @change="change"
        @focus="focus"
        :menu-props="{ auto: true, botton: true, offsetY: true }"
    >
        >
    </v-select>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'selectTipopersona',
    props: ['value', 'texto', 'disabled', 'dense'],
    data: function() {
        return {
            pvalue: 0,
            ptexto: ''
        };
    },
    computed: {
        ...mapState('tipopersona', ['all'])
    },
    methods: {
        ...mapActions('tipopersona', ['getAll']),
        change(id) {
            this.$emit('input', id);
            var r = this.all.items.find(function(item) {
                if (item.id === id) {
                    return item.nombre;
                }
            });
            this.$emit('texto', r.nombre);
            this.$emit('change', r);
        },
        focus() {
            this.pvalue = this.value;
            this.ptexto = this.texto;
        }
    },
    mounted() {
        this.getAll();
    }
};
</script>
