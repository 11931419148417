<template>
    <!-- <v-toolbar-items v-if="showed(valores)" class="hidden-sm-and-down"> -->
    <v-chip :color="config.menuColor" :dark="config.dark == '1' || this.config.ditemDark == 'true'" class="pa-0">
        <v-menu left :offset-x="offsetX" :offset-y="offsetY">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" class="text-none">
                    <v-icon class="mr-1">{{valores.icon}}</v-icon>
                    {{valores.title}}
                </v-btn>
            </template>

            <v-list :color="config.navColor" class="pa-0">
                <div v-for="(item,key, index) in valores.subMenu" :key="index">
                    <v-list-item class="pl-1 pr-1" :color="config.menuColor" :dark="config.dark" :to="item.ruta">
                        <navItem :config="config" 
                                 :valores="item" 
                                 @click="$emit('logout')"></navItem>
                    </v-list-item>
                </div>
            </v-list>
        </v-menu>
    </v-chip>
    <!-- </v-toolbar-items> -->
</template>

<script>
    import navItem from "@/components/Tools/Navigation/navItem";

    export default {
        name: "navMenuItem",
        props: ["valores", "navColor", "config"],
        data() {
            return {
                offsetX: false,
                offsetY: true
            };
        },
        methods: {
            showed(item) {
                return item.show != false;
            }
        },
        components: {
            navItem
        }
    };
</script>

<style>
</style>