const state = {
    type: null,
    message: null,
    color: null,
    timeout: -1,
    snackbar: false
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('clear', '');
        commit('error', message);
    },

    errorTime({ commit }, message) {
        if (Array.isArray(message)) {
            let mensj = '';
            for (var i = 0; i < message.length; i++) {
                mensj += message[i]+'\r\n';
            }
            commit('errorTime', mensj);
        } else {
            commit('errorTime', message);
        }
    },
    clear({ commit }, message) {
        commit('clear', message);
    }
};

const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
        state.color = 'success';
        state.timeout = 3000;
        state.snackbar = true;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
        state.color = 'error';
        state.timeout = -1;
        state.snackbar = true;
    },
    errorTime(state, message) {
        state.type = 'alert-danger';
        state.message = message;
        state.color = 'error';
        state.timeout = 4000;
        state.snackbar = true;
    },
    clear(state) {
        state.type = null;
        state.message = null;
        state.color = null;
        state.snackbar = false;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
