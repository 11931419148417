import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

import colors from 'vuetify/lib/util/colors';

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es'
    },

    theme: {
        light: {
            primary: {
                base: colors.brown.base,
                darken1: colors.brown.darken2
            },
            secondary: '#b0bec5',
            anchor: '#8c9eff'
        }
    },

    icons: {
        iconfont: 'mdi'
    }
});
