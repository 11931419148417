<template>
    <v-container>
        <v-row>
            <v-card class="mx-auto mt-10" max-width="500px">
                <v-card-title class="pa-0">
                    <v-toolbar flat color="yellow">
                        <v-toolbar-title dark class="display-1">
                            Exporta Bajas a Excel
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <div>
                        <v-btn icon class="mt-10 mb-6" @click="exportaBajas">
                            <v-icon x-large>mdi-file-excel</v-icon>
                        </v-btn>
                    </div>
                    <v-progress-linear indeterminate
                                       :active="bajasProgress"
                                       color="yellow darken-2"></v-progress-linear>
                </v-card-text>
                <v-card-actions class="pa-0">
                    <v-toolbar flat dense color="yellow">
                        <v-btn icon @click="closeDialog" class="pl-10">
                            <v-icon>mdi-close</v-icon>
                            Cierra
                        </v-btn>
                    </v-toolbar>
                </v-card-actions>
            </v-card>
            <v-card class="mx-auto mt-10" max-width="500px">
                <v-card-title class="pa-0">
                    <v-toolbar flat color="yellow">
                        <v-toolbar-title dark class="display-1">
                            Exporta Guardas a Excel
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <div>
                        <v-btn icon class="mt-10 mb-6" @click="exportaGuardas">
                            <v-icon x-large>mdi-file-excel</v-icon>
                        </v-btn>
                    </div>
                    <v-progress-linear indeterminate
                                       :active="guardasProgress"
                                       color="yellow darken-2"></v-progress-linear>
                </v-card-text>
                <v-card-actions class="pa-0">
                    <v-toolbar flat dense color="yellow">
                        <v-btn icon @click="closeDialog" class="pl-10">
                            <v-icon>mdi-close</v-icon>
                            Cierra
                        </v-btn>
                    </v-toolbar>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { router } from '../_helpers';

    export default {
        name: 'ExportaCasos',
        data() {
            return {};
        },
        methods: {
            ...mapActions('caso', ['exportaBajas']),
            ...mapActions('guarda', ['exportaGuardas']),

            closeDialog() {
                router.push({ name: 'home' });
            }
        },

        computed: {
            ...mapState('caso', { bajasProgress: ['progress'] }),
            ...mapState('guarda', { guardasProgress: ['progress'] })
        }
    };
</script>
