import { imageService } from '../_services';
import { router } from '../_helpers';

let service = imageService;

const state = {
    items: [],
    index: -1,
    item: {},
    defaultItem: {
        id: 0,
        nombre: ''
    },
    progress: false
};

const actions = {
    getAll({ dispatch, commit }, domain) {
        commit('getAllRequest');

        service.getAll(domain).then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    postObject({ dispatch, commit}, obj) {
        commit('postRequest', obj);

        return service.postObject(obj).then(
            registro => {
                commit('postSuccess', obj.payload.fileName);
                return registro;
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    deleteObject({ commit }, item) {
        commit('deleteRequest');
        service.deleteObject(item).then(
            () => commit('deleteSuccess', item.image),
            error => commit('deleteFailure', { id: item.image, error: error.toString() })
        );
    },

    //putObject({ dispatch, commit, state }, obj) {
    //    commit('putRequest', obj);

        //        service.putObject(obj).then(
        //            registro => commit('putSuccess', registro),
        //            error => {
        //                commit('failure', error);
        //                if (error.response.status == 401) {
        //                    dispatch(
        //                        'alert/errorTime',
        //                        'Error de permisos o sesión expirada!',
        //                        {
        //                            root: true
        //                        }
        //                    );
        //                    router.push({ name: 'login' });
        //                } else {
        //                    dispatch('alert/errorTime', error.message, {
        //                        root: true
        //                    });
        //                }
        //            }
        //            //error => commit('putFailure', { obj, error: error.toString() })
        //        );
        //    },

        //    setItem({ commit }, payload) {
        //        commit('setItem', payload);
        //    },

        //    setIndex({ commit }, payload) {
        //        commit('setIndex', payload);
        //    },

        //    pushChild({ commit }, payload) {
        //        commit('pushChild', payload);
        //    }
    };

    const mutations = {
        // GetAll
        getAllRequest(state) {
            state.items = [];
            state.progress = true;
        },
        getAllSuccess(state, registro) {
            state.items = registro.data;
            state.progress = false;
        },
        failure(state) {
            state.items = [];
            state.progress = false;
        },

        // Post
        postRequest(state) {
            state.progress = true;
        },
        postSuccess(state, registro) {
            if (state.items == null)
                state.items = [];

            state.progress = false;
            state.items.push(registro);
        },

        //    // Put
        //    putRequest(state) {
        //        state.progress = true;
        //    },
        //    putSuccess(state, registro) {
        //        state.progress = false;
        //    },

        deleteRequest(state) {
            state.progress = true;
        },

        deleteSuccess(state, id) {
            state.progress = false;
            // add 'deleting:true' property to user being deleted
            state.items = state.items.filter(item => item !== id);
        },

        deleteFailure(state, { id, error }) {
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            state.items.items = state.items.map(user => {
                if (user.id === id) {
                    // make copy of user without 'deleting:true' property
                    const { ...userCopy } = user;
                    // return copy of user with 'deleteError:[error]' property
                    return { ...userCopy, deleteError: error };
                }

                return user;
            });
            state.progress = false;
        },

        //    setItem(state, payload) {
        //        state.item = payload;
        //        if (payload.id == 0) {
        //            state.index = -1;
        //        }
        //    },

        //    setIndex(state, payload) {
        //        state.index = payload;
        //    },

        //    pushChild(/*state, payload*/) {
        //        //state.item.casoTramite.push(payload);
        //    }
    };

    export const image = {
        namespaced: true,
        state,
        actions,
        mutations
    };
