import { campotramiteService } from '../_services';
import { router } from '../_helpers';

let service = campotramiteService;

const state = {
    items: [],
    item: {},
    id: 0,
    progress: false,
    processed: false,
    error: false,
    dialog: false,

    deflt: {
        id: 0,
        nombre: ''
    }
};

const actions = {
    initialize({ dispatch }) {
        dispatch('getAll');
    },

    editItem({ dispatch, commit }, itm) {
        if (state.dialog) commit('setDialog', false);

        if (itm === null) {
            dispatch('setItem', JSON.parse(JSON.stringify(state.deflt)));
        } else {
            dispatch('getById', itm.id);
        }
        commit('setDialog', true);
    },

    valida({ state, dispatch, commit }, completa) {
        return true;
    },

    saveItem({ dispatch, commit }) {
        if (state.item.id > 0) {
            dispatch('putObject');
        } else {
            dispatch('postObject');
        }
        commit('setDialog', false);
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
    },

    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getById({ commit }, id) {
        commit('getByRequest');

        service.getById(id).then(
            registro => commit('getBySuccess', registro),
            error => commit('failure', error)
        );
    },

    //getxyyyy({ commit }, yyyyId) {
    //    commit('getAllRequest');

    //    service
    //        .getxyyyy(yyyyId)
    //        .then(
    //            registro => commit('getAllSuccess', registro),
    //            error => commit('failure', error)
    //        );
    //},

    postObject({ dispatch, commit }) {
        commit('postRequest', state.item);

        return service.postObject(state.item).then(
            registro => commit('postSuccess', registro),
            error =>
                dispatch('alert/errorTime', error.response.data, { root: true })
        );
    },

    putObject({ dispatch, commit }) {
        commit('putRequest', state.item);

        service.putObject(state.item).then(
            registro => commit('putSuccess', registro),
            error =>
                dispatch('alert/errorTime', error.response.data, { root: true })
        );
    },

    deleteObject({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            campoTramite => commit('deleteSuccess', id),
            error => commit('failure', { id, error: error.toString() })
        );
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    }
};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.items = [];
        state.progress = true;
    },

    getAllSuccess(state, registro) {
        state.items = registro;
        state.progress = false;
    },

    failure(state, error) {
        state.id = 0;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.item.detalles = [];
        state.item.detalles1 = [];
        state.id = registro.id;
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },

    postSuccess(state, registro) {
        state.items.push(registro);
        state.item = registro;
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },

    putSuccess(state, registro) {
        var i = state.items.find(x => x.id == registro.id);
        Object.assign(i, registro);

        state.progress = false;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to campoTramite being deleted
        state.items = state.items.map(campoTramite =>
            campoTramite.id === id
                ? { ...campoTramite, deleting: true }
                : campoTramite
        );
    },

    deleteSuccess(state, id) {
        // remove deleted campoTramite from state
        state.items = state.items.filter(
            campoTramite => campoTramite.id !== id
        );
        state.progress = false;
    },

    setDialog(state, payload) {
        state.dialog = payload;
        if (payload == false) {
            state.item = JSON.parse(JSON.stringify(state.deflt));
        }
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    }
};

export const campotramite = {
    namespaced: true,
    state,
    actions,
    mutations
};
