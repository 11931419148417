import { desarmaderoService } from '../_services';
import { router } from '../_helpers';

let service = desarmaderoService;

const state = {
    items: [],
    dialog: false,
    item: {},
    itemCompact: {},
    itemsDTVTB: {},
    modificado: false,
    progress: false,

    deflt: {
        id: 0,
        codRUDAC: '',
        contacto: '',
        cuit: '',
        direccion: '',
        email: '',
        localidad: { nombre: '', id: null },
        nombre: '',
        nombreRUDAC: '',
        provincia: { nombre: '', id: null },
        telefono: '',
        vencimientoRUDAC: null,
        zonainfluencia: { nombre: '', id: null },
        cliDes: [],
        desTVhTBjDTO: [],
        desarmaderoTVehiculoTBaja: []
    },
};

const actions = {
    async initialize({ dispatch, commit, state }) {
        commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
        dispatch('tipobaja/getAll', null, { root: true });
        dispatch('tipvehiculo/getAll', null, { root: true });
        dispatch('desarmadero/getAllTVehiculoTBaja', null, { root: true });
        dispatch('desarmadero/setDefaultItem', null, { root: true });
    },

    editItem({ dispatch, commit, state, rootState }, itm) {
        if (state.dialog) commit('setDialog', false);

        if (itm === null) {
            commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
            state.item.applicationUserId = rootState.account.loggedUser.id;
            state.item.applicationUser = {
                id: rootState.account.loggedUser.id,
                userName: rootState.account.loggedUser.userName,
                email: rootState.account.loggedUser.email
            };
            dispatch('desarmadero/setDefaultItem', null, { root: true });
        } else {
            dispatch('getById', itm.id);
        }
        commit('setDialog', true);
    },

    getById({ dispatch, commit, state, rootState }, id) {
        var self = this;

        commit('getByRequest');

        service.getById(id).then(
            registro => {
                commit('getBySuccess', registro);
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    async getCompact({ dispatch, commit, state, rootState }) {
        commit('getAllRequest');
        try {
            commit('getCompactSuccess', await service.getCompactAsync({
                clientes: rootState.account.loggedUser.userClientes,
                applicationUserId: rootState.account.loggedUser.id,
                itemsPerPage: 0,
                page: 0
            }));
        }
        catch (error) {
            commit('failure', error);
            if (error.response.status == 401) {
                dispatch(
                    'alert/errorTime',
                    'Error de permisos o sesión expirada!',
                    {
                        root: true
                    }
                );
                router.push({ name: 'login' });
            } else {
                dispatch('alert/errorTime', error.message, {
                    root: true
                });
            }
        }
    },

    async getAllAsync({ dispatch, commit, state }) {
        commit('getAllRequest');
        try {
            var ret = await service.getAllAsync();
            commit('getAllSuccess', ret.data);
        }
        catch (error) {
            commit('failure', error);
            if (error.response.status == 401) {
                dispatch(
                    'alert/errorTime',
                    'Error de permisos o sesión expirada!',
                    {
                        root: true
                    }
                );
                router.push({ name: 'login' });
            } else {
                dispatch('alert/errorTime', error.message, {
                    root: true
                });
            }
        }
    },

    setDefaultItem({ commit, state, rootState }) {
        state.item = JSON.parse(JSON.stringify(state.deflt));
        if (!rootState.tipvehiculo.all.loading && !rootState.tipobaja.all.loading)
            rootState.tipvehiculo.all.items.forEach((val) => {
                rootState.tipobaja.all.items.forEach((val1) => {
                    state.item.desTVhTBjDTO.push({
                        desarmaderoId: 0,
                        tVehiculoId: val.id,
                        tVehiculo: val.nombre,
                        tBajaId: val1.id,
                        tBaja: val1.nombre,
                        porcentaje: val1.porcentaje
                    });
                });

            });
    },

    getAllTVehiculoTBaja({ dispatch, commit, state }) {
        commit('getAllTVehiculoTBajaRequest');

        service.getAllTVehiculoTBaja().then(
            registro => commit('getAllTVehiculoTBajaSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    checkVencimientoById({ dispatch, commit, state }, payload) {
        commit('getByRequest');

        service.getById(payload.id).then(
            registro => {
                commit('getBySuccess', registro);

                if (
                    registro.vencimientoRUDAC == null ||
                    registro.vencimientoRUDAC == undefined ||
                    registro.vencimientoRUDAC < payload.finicio
                ) {
                    dispatch(
                        'alert/errorTime',
                        'Rudac vencido o proximo a vencer',
                        {
                            root: true
                        }
                    );
                }
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getxcliente({ commit }, cliId) {
        commit('getAllRequest');

        service.getxcliente(cliId).then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    postObject({ dispatch, commit, state }) {
        commit('postRequest', state.item);

        return service.postObject(state.item).then(
            registro => {
                commit('setItemCompact', registro);
                commit('postSuccess');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    deleteObject({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            user => commit('deleteSuccess', id),
            error => commit('deleteFailure', { id, error: error.toString() })
        );
    },
    putObject({ dispatch, commit, state }) {
        commit('putRequest', state.item);

        service.putObject(state.item).then(
            registro => {
                commit('setItemCompact', registro);
                commit('putSuccess');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
            //error => commit('putFailure', { obj, error: error.toString() })
        );
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setModificado({ state }, val) {
        state.modificado = val;
    },

    setIndex({ commit }, payload) {
        commit('setIndex', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    },

    saveItem({ dispatch, commit }) {
        if (state.item.id > 0) {
            dispatch('putObject');
        } else {
            dispatch('postObject');
        }
        commit('setDialog', false);
    },

    setCliDes({ commit }, payload) {
        commit('setCliDes', payload);
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
    },
};

const mutations = {
    // GetAll 
    getAllRequest(state) {
        state.all = { loading: true };
        state.progress = true;
    },
    getAllSuccess(state, registro) {
        state.items = registro;
        state.modificado = false;
        state.progress = false;
    },

    getCompactSuccess(state, payload) {
        let self = this;
        state.items = payload.data;
        state.modificado = false;
        state.progress = false;
    },

    setRelation(state) {

    },
    getAllTVehiculoTBajaRequest(state) {
        state.all = { loading: true };
        state.progress = true;
    },
    getAllTVehiculoTBajaSuccess(state, registro) {
        state.itemsDTVTB = registro;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.modificado = false;
        state.id = registro.id;
        state.progress = false;
    },

    failure(state, error) {
        state.all = { error };
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },
    postSuccess(state, registro) {
        state.items.push(state.itemCompact);
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },
    putSuccess(state) {
        var i = state.items.find(x => x.id == state.itemCompact.id);
        var index = state.items.indexOf(i);
        state.items.splice(index, 1, JSON.parse(JSON.stringify(state.itemCompact)));
        state.progress = false;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to user being deleted
        state.items = state.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.items = state.items.filter(user => user.id !== id);
        state.progress = false;
    },
    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.items = state.items.map(user => {
            if (user.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...userCopy } = user;
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error };
            }

            return user;
        });
        state.progress = false;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.index = -1;
        }
    },

    setItemCompact(state, item) {
        state.itemCompact = item;
    },

    setIndex(state, payload) {
        state.index = payload;
    },

    pushChild(/*state, payload*/) {
        //state.item.casoTramite.push(payload);
    },

    setCliDes(state, payload) {
        state.item.cliDes = payload;
    },

    setDialog(state, payload) {
        state.dialog = payload;
        if (payload == false) {
            state.item = JSON.parse(JSON.stringify(state.deflt));
        }
    },

};

export const desarmadero = {
    namespaced: true,
    state,
    actions,
    mutations
};
