<template>
    <!-- <v-chip :color="config.menuColor" :dark="config..dark"> -->
    <v-list-group :prepend-icon="user.icon">
        <template v-slot:activator>
            <v-list-item-avatar>
                <v-img v-if="user.loggedIn && user.avatar!=''" :src="user.avatar"></v-img>
                <v-icon large v-else>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{user.userName}}</v-list-item-title>
        </template>

        <div v-for="(item, i) in menu" :key="i">
            <v-list-item :color="config.navColor" v-if="item.logout!=true" link :to="item.ruta">
                <v-chip :color="config.menuColor" :dark="config.dark">
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-chip>
            </v-list-item>

            <v-list-item :color="config.navColor" v-else link @click="$emit('logout')">
                <v-chip :color="config.menuColor" :dark="config.dark">
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-chip>
            </v-list-item>
        </div>

    </v-list-group>
    <!-- </v-chip> -->
</template>

<script>
    export default {
        name: "navUserDrawerItem",
        props: ["menu", "user", "config"],
        data() {
            return {
                offsetX: false,
                offsetY: true
            };
        },
        computed: {
            userName() {
                return this.user.name;
            }
        },

    };
</script>

<style>
</style>