import Vue from 'vue';
import Router from 'vue-router';
// Static
import Home from '../views/HomePage.vue';

import Compra from '../components/Compra.vue';
import Desarmadero from '../components/Desarmadero/DesarmaderoCrud.vue';
import Estado from '../components/Estado/Estado.vue';
import Estadoinforme from '../components/EstadoInforme.vue';
import ExportaCasos from '../components/ExportaCasos.vue';
import Gestor from '../components/Gestor/GestorCrud.vue';
import ImportaModeloValor from '../components/ImportaModeloValor.vue';
import Localidad from '../components/Localidad.vue';
import Marca from '../components/Marca.vue';
import Modelo from '../components/Modelo.vue';
import Productor from '../components/Productor/ProductorCrud.vue';
import Provincia from '../components/Provincia.vue';
import Registro from '../components/Registro.vue';
import Tipobaja from '../components/TipoBaja.vue';
import Titular from '../components/Titular/Titular.vue';
import TipVehiculo from '../components/TipVehiculo.vue';
import Tramitador from '../components/Tramitador.vue';
import zonainfluencia from '../components/ZonaInfluencia.vue';

Vue.use(Router);

export const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/DirectCaso/:id',
            name: 'DirectCaso',
            component: () => import('../components/Caso/DirectCaso.vue')
        },
        {
            path: '/CasoCrud',
            name: 'CasoCrud',
            component: () => import('../components/Caso/CasoCrud.vue')
        },
        //{
        //    path: '/CasoCompact',
        //    name: 'CasoCompact',
        //    component: CasoCompact
        //},
        {
            path: '/Compra',
            name: 'Compra',
            component: Compra
        },
        {
            path: '/cliente',
            name: 'cliente',
            component: () => import('../views/Cliente.vue')
        },
        {
            path: '/Deposito',
            name: 'Deposito',
            component: () => import('../views/Deposito.vue')
        },
        {
            path: '/Desarmadero',
            name: 'Desarmadero',
            component: Desarmadero
        },
        {
            path: '/Estado',
            name: 'Estado',
            component: Estado
        },
        {
            path: '/EstadoInforme',
            name: 'EstadoInforme',
            component: Estadoinforme
        },
        {
            path: '/ExportaCasos',
            name: 'ExportaCasos',
            component: ExportaCasos
        },
        {
            path: '/Gestor',
            name: 'Gestor',
            component: Gestor
        },
        {
            path: '/Grua',
            name: 'Grua',
            component: () => import('../views/Grua.vue')
        },
        {
            path: '/Guarda',
            name: 'Guarda',
            component: () => import('../views/Guarda.vue')
        },
        {
            path: '/ImportaModeloValor',
            name: 'ImportaModeloValor',
            component: ImportaModeloValor
        },
        {
            path: '/Localidad',
            name: 'Localidad',
            component: Localidad
        },
        {
            path: '/Marca',
            name: 'Marca',
            component: Marca
        },
        {
            path: '/Modelo',
            name: 'Modelo',
            component: Modelo
        },
        {
            path: '/ModeloValorTipo',
            name: 'ModeloValorTipo',
            component: () => import('../views/ModeloValorTipo.vue')
        },
        {
            path: '/Notificacion',
            name: 'Notificacion',
            component: () => import('../views/Notificacion.vue')
        },
        {
            path: '/Productor',
            name: 'Productor',
            component: Productor
        },
        {
            path: '/Provincia',
            name: 'Provincia',
            component: Provincia
        },
        {
            path: '/Recuperador',
            name: 'Recuperador',
            component: () => import('../views/Recuperador.vue')
        },
        {
            path: '/Registro',
            name: 'Registro',
            component: Registro
        },
        {
            path: '/TipoBaja',
            name: 'TipoBaja',
            component: Tipobaja
        },
        {
            path: '/TipVehiculo',
            name: 'TipVehiculo',
            component: TipVehiculo
        },
        {
            path: '/Titular',
            name: 'Titular',
            component: Titular
        },
        {
            path: '/Tramitador',
            name: 'Tramitador',
            component: Tramitador
        },
        {
            path: '/Tramite',
            name: 'tramite',
            component: () => import('../views/Tramite.vue')
        },
        {
            path: '/Transportista',
            name: 'Transportista',
            component: () => import('../views/Transportista.vue')
        },
        {
            path: '/zonainfluencia',
            name: 'zonainfluencia',
            component: zonainfluencia
        },
        {
            path: '/Usuario',
            name: 'user',
            component: () => import('../views/Usuarios.vue')
        },
        {
            path: '/Role',
            name: 'role',
            component: () => import('../views/Roles.vue')
        },
        {
            path: '/CampoTramite',
            name: 'campotramite',
            component: () => import('../views/CampoTramite.vue')
        },
        {
            path: '/SystemData',
            name: 'systemdata',
            component: () => import('../views/AboutPage.vue')
        },
        {
            path: '/Identity/Login',
            name: 'login',
                component: () => import('../views/Identity/LoginPage.vue')
        },
        {
            path: '/Identity/Logout',
            // Redirige a otro sitio web
            beforeEnter() {
                // Redirige a otro sitio web
                window.location.href = 'https://tf3040.com.ar';
            }
        },
        {
            path: '/Identity/Register',
            name: 'register',
            component: () => import('../views/Identity/RegisterPage.vue')
        },
        {
            path: '/Identity/ConfirmEmail',
            name: 'confirmEmail',
            component: () => import('../views/Identity/ConfirmEmailPage.vue')
        },
        {
            path: '/Identity/Forgot',
            name: 'forgot',
            component: () => import('../views/Identity/ForgotPage.vue')
        },
        {
            path: '/Identity/Reset',
            name: 'reset',
            component: () => import('../views/Identity/ResetPage.vue')
        },
        {
            path: '/Identity/ResendConfirmationEmail',
            name: 'resend',
            component: () =>
                import('../views/Identity/ResendConfirmationEmailPage.vue')
        },
        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ]
});

//this.beforeEach((to, from, next) => {
//    // redirect to login page if not logged in and trying to access a restricted page
//    const publicPages = ['/login', '/register'];
//    const authRequired = !publicPages.includes(to.path);
//    const loggedIn = localStorage.getItem('user');

//    if (authRequired && !loggedIn) {
//        return next('/login');
//    }

//    next();
//});
