<template>
    <v-select :value="value"
              :dense="dense"
              :disabled="disabled"
              :items="items"
              item-text="nombre"
              item-value="id"
              attach
              chips
              label="Clientes"
              @change="change"
              @input="update"
              multiple
              :menu-props="{ auto: true, botton: true, offsetY: true }">
    </v-select>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'selectClientes',
        props: ['value', 'disabled', 'dense'],
        data: function () {
            return {};
        },
        computed: {
            ...mapState('cliente', ['items'])
        },

        methods: {
            ...mapActions('cliente', ['getAll']),

            update(newValue) {
                this.$emit('input', newValue);
            },

            change(value) {
                this.$emit('change', value);
            }

        },
        mounted() {
            this.getAll();
        }
    };
</script>
