import axios from 'axios';

import { authHeader, token } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
var API = 'api/caso/';
var APIImage = 'api/image/';
var APIQry = 'api/casoQuery/';

// Exporta metodos
export const casoService = {
    anulado,
    casosExporta,
    getAll,
    getThumbnails,
    getFile,
    getAPIServer,
    getCompactAsync,
    getAlertCompact,
    getUserCompact,
    getCompact,
    getCompactById,
    getCompactPaging,
    getModeloValor,
    //    getxyyyy,
    getById,
    getByGuid,
    getByDominioSiniestro,
    orderNotificaciones,
    orderTramites,
    putObject,
    postObject,
    deleteObject
};

function orderTramites(caso) {
    // Ordena descendente
    var tramites = caso.casoTramite.sort((a, b) => {
        if (a.fInicio > b.fInicio) {
            return 1;
        }
        if (a.fInicio < b.fInicio) {
            return -1;
        }
        // a must be equal to b
        if (a.tramiteId > b.tramiteId) {
            return 1;
        }
        if (a.tramiteId < b.tramiteId) {
            return -1;
        }
        return 0;
    });

    // Invierte el orden
    return tramites.reverse();
}

function orderNotificaciones(caso) {

    // Ordena descendente
    var notificaciones = caso.notificacion.sort((a, b) => {
        if (a.tramiteId > b.tramiteId) {
            return 1;
        }
        if (a.tramiteId < b.tramiteId) {
            return -1;
        }
        // a must be equal to b
        if (a.tramiteId > b.tramiteId) {
            return 1;
        }
        if (a.tramiteId < b.tramiteId) {
            return -1;
        }
        return 0;
    });

    // Invierte el orden
    return notificaciones;//.reverse();
}

function anulado(tramites) {
    var anulado = 0;
    var reactivado = 0;

    tramites.map(x => {
        anulado += x.tramiteId == 1 ? 1 : 0; // Anulados
        reactivado += x.tramiteId == 5 ? 1 : 0; // Reactivado
    });
    if (anulado > reactivado) return 2;
    return 0;
}

// Lee todos los elementos
function getAll(clientes) {
    return axios
        .post(urlAPI + API + 'cliente/', clientes, authHeader())
        .then(registro => {
            return registro.data;
        });
}

function getThumbnails(payload) {
    return axios
        .post(urlAPI + APIImage + 'thumbnails', payload, authHeader())
        .then(registro => {
            return registro.data;
        });
}

function getAPIServer() {
    var ret = (urlAPI + APIImage);
    return ret;
}

function getFile(payload, file) {
    return axios
        .post(urlAPI + APIImage + 'download-file?file=' + file, payload, authHeader())
        .then(registro => {
            return registro.data;
        });
}

async function getCompactAsync(clientes) {
    var ret = axios.post(urlAPI + API + 'cliente/', clientes, authHeader())
    return ret;
}

// Lee todos los elementos compact
function getCompact(modelo) {
    //return axios.post(urlAPI + API + 'compact', modelo, authHeader()).then(registro => {

    return axios.post(urlAPI + APIQry + 'compact', modelo, authHeader()).then(registro => {
        return registro.data;
    });
}

function getAlertCompact(modelo) {
    //return axios.post(urlAPI + API + 'compact', modelo, authHeader()).then(registro => {

    return axios.post(urlAPI + APIQry + 'alerts', modelo, authHeader()).then(registro => {
        return registro.data;
    });
}

function getUserCompact(modelo) {
    //return axios.post(urlAPI + API + 'compact', modelo, authHeader()).then(registro => {

    return axios.post(urlAPI + APIQry + 'user', modelo, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee un solo elemento de la entidad
function getCompactById(id) {
    return axios.get(urlAPI + API + id, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee todos los elementos compactPaging
function getCompactPaging(payLoad) {
    return axios
        .post(urlAPI + API + 'compact/Paging', payLoad, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// Lee datos de modelo valor
function getModeloValor(modeloValorId) {
    return axios.get(urlAPI + API + 'Infoauto/' + modeloValorId, authHeader()).then(registro => {
        return registro.data;
    });
}

//// Lee solo los elementos relacionados con el id de la entidad padre yyyy
//function getxyyyy(id) {
//    return axios.get(urlAPI + API + 'yyyy/' + id, authHeader()).then(registro => {
//        return registro.data;
//    });
//}

// Lee un solo elemento de la entidad
function getById(id) {
    return axios.get(urlAPI + API + id, authHeader()).then(registro => {
        registro.data.notificacion = [];
        return registro.data;
    });
}

// Lee un solo elemento de la entidad
function getByGuid(id) {
    return axios.get(urlAPI + API + 'Guid/' + id/*, authHeader()*/).then(registro => {
        registro.data.notificacion = [];
        return registro.data;
    });
}

// Lee un solo elemento de la entidad
function getByDominioSiniestro(ds) {
    return axios.get(urlAPI + API + 'DominioSiniestro/' + ds/*, authHeader()*/).then(registro => {
        registro.data.notificacion = [];
        return registro.data;
    });
}

function prepareSet(obj) {
    return {
        id: obj.id,
        borrado: obj.borrado,
        anio: obj.anio,
        applicationUserId: obj.applicationUser.id,
        casoAlerta: obj.casoAlerta,
        casoTramite: obj.casoTramite,
        clienteId: obj.cliente.id == 0 ? null : obj.cliente.id,
        deudaMulta: obj.deudaMulta,
        deudaPatente: obj.deudaPatente,
        desarmaderoId: obj.desarmadero.id == 0 ? null : obj.desarmadero.id,
        dropboxLink: obj.dropboxLink,
        estadoId: obj.estadoId == 0 ? null : obj.estadoId,
        estadoInformeId: obj.estadoInforme.id == 0 ? null : obj.estadoInforme.id,
        fechaSiniestro: obj.fechaSiniestro,
        gestorId: obj.gestor.id == 0 ? null : obj.gestor.id,
        guid: obj.guid,
        marcaId: obj.marca.id == 0 ? null : obj.marca.id,
        modeloId: obj.modelo.id == 0 ? null : obj.modelo.id,
        modeloValorId: obj.modeloValor.modeloValorId == 0 ? null : obj.modeloValor.modeloValorId,
        nombre: obj.nombre,
        productorId: obj.productor.id == 0 ? null : obj.productor.id,
        registroId: obj.registro.id == 0 ? null : obj.registro.id,
        siniestro: obj.siniestro,
        tipoBajaId: obj.tipoBaja.id == 0 ? null : obj.tipoBaja.id,
        titularId: obj.titular.id == 0 ? null : obj.titular.id,
        tramitadorId: obj.tramitador.id == 0 ? null : obj.tramitador.id
    }
}

// Post de la entidad
function postObject(obj) {
    var modelo = prepareSet(obj);

    return axios.post(urlAPI + API, modelo, authHeader()).then(registro => {
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    var modelo = prepareSet(obj);

    return axios
        .put(urlAPI + API + modelo.id, modelo, authHeader()).then(registro => {
            return registro.data;
        });
}

function casosExporta() {
    var aut = token();
    return axios({
        url: urlAPI + 'api/caso/' + 'download/',
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: aut }
    });
}

// delete de la entidad
function deleteObject(obj) {
    return axios.delete(urlAPI + API + obj, authHeader()).then(registro => {
        return registro.data;
    });
}
