<template>
    <v-data-table :headers="headers"
                  dense
                  :items="all.items"
                  sort-by="id"
                  :search="search"
                  class="elevation-1"
                  @click:row="editItem">
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Tpo de Baja</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="400px" persistent>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               v-on:click="editItem(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6>
                                            <v-text-field v-model="item.nombre"
                                                          label="Nombre"></v-text-field>
                                        </v-flex>

                                        <v-flex xs11 sm3>
                                            <v-text-field v-model="item.porcentaje"
                                                          label="Porcentaje"></v-text-field>
                                        </v-flex>

                                        <v-flex xs1>
                                            <v-btn class="ma-1"
                                                   fab
                                                   color="yellow"
                                                   @click="dialogoIconos = true">
                                                <v-icon dark>{{ item.icono }}</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                            <template>
                                <v-dialog v-model="dialogoIconos" max-width="800px" persistent>
                                    <iconos v-model="item.icono"
                                            @close="dialogoIconos = false"></iconos>
                                </v-dialog>
                            </template>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancela</v-btn>
                            <v-btn color="blue darken-1" text @click="save">Guarda</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.icono="{ item }">
            <v-icon>{{item.icono}}</v-icon>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import iconos from '@/components/Tools/Iconos';

    export default {
        name: 'tipobaja',
        data() {
            return {
                dialog: false,
                dialogoIconos: false,
                search: '',
                headers: [
                    {
                        text: 'Id',
                        align: 'left',
                        sortable: true,
                        value: 'id'
                    },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Porcentaje', value: 'porcentaje' },
                    { text: 'Icono', value: 'icono' },
                  { text: 'Opciones', value: 'action', sortable: false }
                ]
            };
        },

        computed: {
            ...mapState('tipobaja', ['all', 'item', 'defaultItem', 'index']),

            formTitle() {
                return this.index === -1
                    ? 'Nuevo Tipo de Baja'
                    : 'Edita Tipo de Baja';
            },

            icono() {
                return 'mdi-plus';
            }

        },

        watch: {
            dialog(val) {
                val || this.close();
            }
        },

        created() {
            this.initialize();
        },

        methods: {
            ...mapActions('tipobaja', [
                'getAll',
                'putObject',
                'postObject',
                'deleteObject',
                'setItem',
                'setIndex'
            ]),

            initialize() {
                this.getAll();
                this.setItem(Object.assign({}, this.defaultItem));
            },

            editItem(itm) {
                if (itm == null) {
                    this.setItem(Object.assign({}, this.defaultItem));
                    this.setIndex(-1);
                } else {
                    var e = this.all.items.findIndex(x => x.id == itm.id);
                    this.setIndex(e);
                    this.setItem(Object.assign({}, itm));
                }
                this.dialog = true;
            },

            deleteItem(item) {
                const index = this.all.items.indexOf(item);
                if (confirm('Desea borrar el Tipo de baja?')) {
                    this.deleteObject(item.id);
                    this.all.items.splice(index, 1);
                }
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.setItem(Object.assign({}, this.defaultItem));
                    this.setIndex(-1);
                }, 300);
            },

            save() {
                if (this.index > -1) {
                    this.putObject(this.item);
                    Object.assign(this.all.items[this.index], this.item);
                } else {
                    this.postObject(this.item).then(tipobaja => {
                        this.all.items.push(tipobaja);
                    });
                }
                this.close();
            }
        },
          components: {
            iconos,
        }
  };
</script>
