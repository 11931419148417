import { render, staticRenderFns } from "./navUserMenu.vue?vue&type=template&id=2f7a8553&"
import script from "./navUserMenu.vue?vue&type=script&lang=js&"
export * from "./navUserMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VIcon,VImg,VList,VListItem,VListItemAvatar,VMenu,VToolbarItems})
