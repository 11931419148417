import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'api/recuperador/';

// Exporta metodos
export const recuperadorService = {
    getAll,
    getById,
    getAutocomplete,
    putObject,
    postObject,
    deleteObject
};

// Lee todos los elementos
function getAll() {
    return axios.get(urlAPI + API, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee un solo elemento de la entidad
function getById(id) {
    return axios.get(urlAPI + API + id, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee un texto incompleto para autocompletar
function getAutocomplete(val) {
    var results = [];
    return axios
        .post(urlAPI + API + 'autocompleta/', { nombre: val }, authHeader())
        .then(response => {
            for (var i = 0; i < response.data.length; i++) {
                results.push({
                    id: response.data[i].id,
                    value:
                        response.data[i].apellido +
                        ', ' +
                        response.data[i].nombre
                });
            }
            return results;
        });
}

// Post de la entidad
function postObject(obj) {
    // Copiar todas las propiedades menos id
    var modelo = {
        nombre: obj.nombre,
        //localidadId: obj.localidad.id,
        borrado: obj.borrado
    };
    return axios.post(urlAPI + API, modelo, authHeader()).then(registro => {
        // Completa nombre e id de la relacion en select
        //registro.data.localidad = {
        //    id: obj.localidad.id,
        //    nombre: obj.localidad.nombre
        //};
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var modelo = {
        id: obj.id,
        nombre: obj.nombre,
        //localidadId: obj.localidad.id,
        borrado: obj.borrado
    };
    return axios
        .put(urlAPI + API + modelo.id, modelo, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// delete de la entidad
function deleteObject(obj) {
    return axios.delete(urlAPI + API + obj, authHeader()).then(registro => {
        return registro.data;
    });
}
