<template>
    <div class="text-center">
        <v-snackbar v-model="propSnackbar"
                    top
                    light
                    :multi-line="ml"
                    :timeout="timeout"
                    :color="color">
            {{ message }}
            <v-btn text @click="clear">
                Ok
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'Alerta',
        data() {
            return {
                ml: true,
            };
        },

        computed: {
            ...mapState('alert', [
                'snackbar',
                'color',
                'type',
                'message',
                'timeout'
            ]),
            propSnackbar: {
                get() {
                    return this.snackbar;
                },
                set() {
                    this.clear();
                }
            }
        },

        watch: {
            snackbar() { }
        },

        methods: {
            ...mapActions('alert', ['clear'])
        }
    };
</script>
