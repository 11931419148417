import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'api/tramite/';

// Exporta metodos
export const tramiteService = {
    getAll,
    getxestado,
    getById,
    putObject,
    postOrdenObject,
    postObject,
    deleteObject
};

// Lee todos los elementos
function getAll() {
    return axios.get(urlAPI + API, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee solo los elementos relacionados con el id de la entidad padre yyyy
function getxestado(id) {
    return axios
        .get(urlAPI + API + 'estado/' + id, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// Lee un solo elemento de la entidad
function getById(id) {
    return axios.get(urlAPI + API + id, authHeader()).then(registro => {
        return registro.data;
    });
}

// Post de la entidad
function postObject(obj) {
    // Copiar todas las propiedades menos id
    var modelo = {
        nombre: obj.nombre,
        estadoInicialId: obj.estadoInicial.id,
        estadoFinalId: obj.estadoFinal.id,
        modulo: obj.modulo,
        orden: obj.orden,
        tramiteCampo: obj.tramiteCampo.filter(x => x.visible == 1),
        icono: obj.icono,
        borrado: obj.borrado
    };
    return axios.post(urlAPI + API, modelo, authHeader()).then(registro => {
        // Completa nombre e id de la relacion en select
        registro.data.estadoInicial = {
            id: obj.estadoInicial.id,
            nombre: obj.estadoInicial.nombre
        };
        // Completa nombre e id de la relacion en select
        registro.data.estadoFinal = {
            id: obj.estadoFinal.id,
            nombre: obj.estadoFinal.nombre
        };
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var modelo = {
        id: obj.id,
        nombre: obj.nombre,
        estadoInicialId: obj.estadoInicial.id,
        estadoFinalId: obj.estadoFinal.id,
        modulo: obj.modulo,
        orden: obj.orden,
        tramiteCampo: obj.tramiteCampo.filter(x => x.visible == 1),
        icono: obj.icono,
        borrado: obj.borrado
    };
    return axios
        .put(urlAPI + API + obj.id, modelo, authHeader())
        .then(registro => {
            // Completa nombre e id de la relacion en select
            registro.data.estadoInicial = {
                id: obj.estadoInicial.id,
                nombre: obj.estadoInicial.nombre
            };
            // Completa nombre e id de la relacion en select
            registro.data.estadoFinal = {
                id: obj.estadoFinal.id,
                nombre: obj.estadoFinal.nombre
            };
            return registro.data;
        });
}

// put de la entidad
function postOrdenObject(obj) {
    return axios
        .post(urlAPI + API + 'cambiaorden/', obj, authHeader())
        .then(registro => {
            // Completa nombre e id de la relacion en select
            return registro.data;
        });
}

// delete de la entidad
function deleteObject(obj) {
    return axios.delete(urlAPI + API + obj, authHeader()).then(registro => {
        return registro.data;
    });
}
