<template>
    <v-data-table :headers="headers"
                  dense
                  :loading="progress"
                  :items="items"
                  sort-by="id"
                  :search="search"
                  class="elevation-1"
                  @click:row="selectRow">
        <template v-slot:top>
            <v-toolbar flat dense color="yellow">
                <v-toolbar-title>Desarmadero</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="propDialog"
                          width="1200px"
                          max-heigh="550px"
                          persistent>
                    <template v-slot:activator>
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               v-on:click="editItem(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <desarmaderoDialogo/>
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:item.vencimientoRUDAC="{ item }">
            {{ fechaFrmt(item.vencimientoRUDAC) }}
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>

        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import desarmaderoDialogo from '@/components/Desarmadero/DesarmaderoDialogo';

    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'desarmadero',
        data() {
            return {
                search: '',
                formTitle: '',
                headers: [
                    { text: 'Id', align: 'left', sortable: true, value: 'id' },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Dirección', value: 'direccion' },
                    { text: 'Localidad', value: 'localidad' },
                    { text: 'Provincia', value: 'provincia' },
                    { text: 'CUIT', value: 'cuit' },
                    { text: 'Zona Influencia', value: 'zonainfluencia' },
                    { text: 'Código RUDAC', value: 'codRUDAC' },
                    { text: 'Razón social RUDAC', value: 'nombreRUDAC' },
                    { text: 'Venc. RUDAC', value: 'vencimientoRUDAC' },
                    { text: 'Teléfono', value: 'telefono' },
                    { text: 'e-mail', value: 'email' },
                    { text: 'Contacto', value: 'contacto' },
                    { text: 'Opciones', value: 'action', sortable: false }
                ]
            };
        },

        computed: {
            ...mapState('desarmadero', [
                'items',
                'dialog',
                'item',
                'progress']),

        propDialog: {
                get() {
                    return this.dialog;
                },
                set(value) {
                    this.setDialog(value);
                }
            },
        },

        async created() {
            var self = this;
            await this.initialize();
            await self.getCompact();
        },

        methods: {
            ...mapActions('desarmadero', [
                'initialize',
                'editItem',
                'getCompact',
                'setDialog',
                'putObject',
                'postObject',
                'deleteObject',
                'setIndex'
            ]),

            changeClientes(/*value*/) {
                //var clientes = [];

                //value.forEach(item =>
                //    clientes.push(this.item.id)
                //);
                //this.item.cliDes = clientes;
            },

            deleteItem(item) {
                const index = this.items.indexOf(item);
                if (confirm('Desea borrar el desarmadero?')) {
                    this.deleteObject(item.id);
                    this.items.splice(index, 1);
                }
            },

            fechaFrmt(val) {
                if (val === null || val === undefined) return null;
                var str =
                    val.substring(8, 10) +
                    '/' +
                    val.substring(5, 7) +
                    '/' +
                    val.substring(2, 4);
                return str;
            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            selectRow(itm) {
                this.editItem(itm);
            },
        },
        components: {
            desarmaderoDialogo,
        }
    };
</script>
