<template>
    <v-data-table
        :headers="headers"
        dense
        :items="item.estadoTramite"
        sort-by="orden"
        hide-default-footer
        hide-default-header
        class="elevation-1"
    >
        <template v-slot:top>
            <v-toolbar flat color="grey lighten-2">
                <v-dialog v-model="dialog" 
                          max-width="500px" 
                          persistent>
                    <template v-slot:activator>
                        <h3>Trámites asociados</h3>
                        <v-btn
                            class="mx-3"
                            small
                            fab
                            dark
                            x-small
                            color="warning"
                            v-on:click="nuevoTramite"
                        >
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">
                                {{ formTitle }}
                            </span>
                        </v-card-title>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <v-flex xs12>
                                            <v-select
                                                v-model="editedItem.tramiteId"
                                                :items="items"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Trámite"
                                            >
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close"
                                >Cancela</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="save"
                                >Guarda</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
                delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>

        <template v-slot:item.default="{ item }">
            <v-flex xs12>
                <v-checkbox v-model="item.default" @change="setDefault(item)">
                </v-checkbox>
            </v-flex>
        </template>
        <template v-slot:item.posicion="{ item }">
            <v-flex xs11>
                <ArribaAbajo
                    v-model="item.orden"
                    :max="maxItems"
                    @change="change(item, $event)"
                >
                </ArribaAbajo>
            </v-flex>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ArribaAbajo from '@/components/Tools/ArribaAbajo';

export default {
    name: 'estadoTramite',
    data() {
        return {
            dialog: false,
            tramiteId: -1,

            headers: [
                // { text: 'Id', align: 'left', sortable: true, value: 'tramite.id' },
                { text: 'Orden', value: 'orden', sortable: true },
                { text: 'Nombre', value: 'tramite.nombre' },
                { text: 'Default', value: 'default' },
                { text: 'Posicion', value: 'posicion' },
                { text: 'Opciones', value: 'action', sortable: false }
            ],
            editedIndex: -1,
            editedItem: {
                estadoId: 0,
                tramiteId: 0,
                default: false,
                orden: 0,
                tramite: { nombre: '', id: 0 }
            },
            defaultItem: {
                estadoId: 0,
                tramiteId: 0,
                default: false,
                orden: 0,
                tramite: { nombre: '', id: 0 }
            }
        };
    },

    computed: {
        ...mapState('tramite', ['items']),
        ...mapState('estado', ['item']),
        maxItems() {
            return this.item.estadoTramite.length;
        },
        formTitle() {
            return this.editedIndex === -1
                ? 'Nuevo estadotramite'
                : 'Edita estadotramite';
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        }
    },

    created() {
        this.getAll();
    },

    methods: {
        ...mapActions('tramite', ['getAll']),
        ...mapActions('estado', ['setDefault', 'changePosition']),

        initialize() {},

        nuevoTramite() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedItem.estadoId = this.item.id;
            this.dialog = true;
        },

        deleteItem(itm) {
            const index = this.item.estadoTramite.indexOf(itm);
            if (confirm('Desea borrar el estadotramite?')) {
                this.item.estadoTramite.splice(index, 1);
            }
        },

        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedIndex = -1;
            }, 300);
        },

        change(item, inc) {
            this.changePosition({ item, inc });
        },

        save() {
            var itmid = this.editedItem.tramiteId;
            var r = this.items.find(function(itm) {
                if (itm.id === itmid) {
                    return itm.nombre;
                }
            });
            this.editedItem.tramite = {
                id: this.editedItem.tramiteId,
                nombre: r.nombre
            };
            this.editedItem.orden = this.item.estadoTramite.length + 1;

            this.item.estadoTramite.push(this.editedItem);
            this.close();
        }
    },
    components: {
        ArribaAbajo
    }
};
</script>
