<template>
    <v-list-group :prepend-icon="valores.icon">
        <template v-slot:activator>
            <v-list-item-title>{{valores.title}}</v-list-item-title>
        </template>
        <div v-for="(item, i) in valores.subMenu" :key="i">
            <v-list-item v-if="valores.logout!=true" link :to="item.ruta">
                <v-chip :color="config.menuColor" :dark="config.dark">
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-chip>
            </v-list-item>

            <v-list-item v-else link @logout="$emit('logout')">
                <v-chip :color="config.menuColor" :dark="config.dark">
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-chip>
            </v-list-item>
        </div>
    </v-list-group>
</template>

<script>
    import navDrawerItem from "@/components/Tools/Navigation/navDrawerItem";

    export default {
        name: "navMenuDrawerItem",
        props: ["valores", "navColor", "config"],
        data() {
            return {
                offsetX: false,
                offsetY: true
            };
        },

        components: {
            navDrawerItem
        }
    };
</script>

<style>
</style>