import { ESTADOS } from './';
import { TRAMITES } from './';
import { guardaService } from '../_services';
import { router } from '../_helpers';

let service = guardaService;

const state = {
    items: [],
    item: {},
    tramite: {},
    id: 0,
    modificado: false,
    progress: false,
    processed: false,
    error: false,
    dialog: false,
    borraDialog: false,
    dialogTramite: false,
    todosTramitesCompletados: false,

    deflt: {
        id: 0,
        nombre: '',
        clienteId: null,
        cliente: { nombre: '', id: null },
        marcaModelo: '',
        codigo: '',
        siniestro: '',
        estadoId: 12, //ESTADOS.SIN_INICIAR,
        estado: { nombre: 'SIN INICIAR', id: 12 },
        modeloValorId: null,
        modeloValor: { marcaId: null, marca: '', modeloId: null, modelo: '', tipoId: null, tipo: '' },
        marcaId: null,
        marca: { nombre: '', id: null },
        modeloId: null,
        modelo: { nombre: '', id: null },
        anio: null,
        dropboxLink: '',
        motor: '',
        chasis: '',
        color: '',
        applicationUserId: null,
        applicationUser: { username: '', id: null , email: ''},
        guardaTramite: [],
        guardaAlerta: [],
        deposito: { nombre: '', id: null },
        depositoId: null,
        puestoId: null,
        borrado: false,
    },

    defltTramite: {
        id: 0,
        tramiteId: 0,
        fInicio: null,
        fCierre: null,
        descripcion: '',
        usuarioId: 0,
        completado: false,
        numeroComprobante: '',
        fechaComprobante: null,
        importeComprobante: null,
        depositoId: null,
        puestoId: null,
        deposito: { nombre: '', id: null },

        orden: 0,
        borrado: false,
        nuevoTramite: false,
    }

};

const actions = {
    async initialize({ dispatch, commit }) {
        await dispatch('estado/getAllAsync', null, { root: true });
        dispatch('modelovalortipo/getAll', null, { root: true });
        //dispatch('modelovalor/getAll', null, { root: true });
        dispatch('tramite/getAll', null, { root: true });
        dispatch('marca/getAll', null, { root: true });
        dispatch('modelo/getAll', null, { root: true });
        dispatch('deposito/getAll', null, { root: true });
        dispatch('cliente/getAll', null, { root: true });
        commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
    },

    editItem({ dispatch, commit }, itm) {
        if (state.dialog) commit('setDialog', false);

        if (itm === null) {
            dispatch('setItem', JSON.parse(JSON.stringify(state.deflt)));
        } else {
            dispatch('getById', itm.id);
        }
        commit('setDialog', true);
    },

    valida({ state, dispatch, commit }, completa) {
        return true;
    },

    saveItem({ dispatch, commit }) {
        if (state.item.id > 0) {
            dispatch('putObject');
        } else {
            dispatch('postObject');
        }
        commit('setDialog', false);
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
    },

    tramitesCompletados() {
        let self = this;
        state.todosTramitesCompletados =
            state.item.guardaTramite.find(
                x =>
                    x.completado == false &&
                    x.tramiteId >= self.state.TRAMITES.INICIAGUARDA &&
                    self.state.tramite.items.find(l => l.id == x.tramiteId).modulo & 8 == 8
            ) == null;
    },

    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getCompact({ dispatch, commit, state, rootState }) {
        commit('getAllRequest');

        return service.getCompact({
            clientes: rootState.account.loggedUser.userClientes,
            finalizados: true,
            applicationUserId: rootState.account.loggedUser.id,
            itemsPerPage: 0,
            page: 0
        }).then(
            registro => commit('getCompactSuccess', { registro }),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    async getCompactAsync({ dispatch, commit, state, rootState }) {
        var self = this;
        commit('getAllRequest');
        try {
            commit('getCompactSuccess', await service.getCompactAsync({
                clientes: rootState.account.loggedUser.userClientes,
                finalizados: true,
                applicationUserId: rootState.account.loggedUser.id,
                itemsPerPage: 0,
                page: 0
            }));
        }
        catch (error) {
            commit('failure', error);
            if (error.response.status == 401) {
                dispatch(
                    'alert/errorTime',
                    'Error de permisos o sesión expirada!',
                    {
                        root: true
                    }
                );
                router.push({ name: 'login' });
            } else {
                dispatch('alert/errorTime', error.message, {
                    root: true
                });
            }
        }
    },

    getAutocomplete({ dispatch, commit, state }, obj) {
        commit('getAutocompleteRequest');
        if (obj.value != undefined) {
            service.getAutocomplete(obj).then(
                registro => commit('getAutocompleteSuccess', registro),
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );
        }
    },

    getById({ dispatch, commit, state }, id) {
        var self = this;

        commit('getByRequest');

        service.getById(id).then(
            registro => {
                // Filtra observaciones internas si es compañia o productor
                registro.guardaTramite = registro.guardaTramite.filter(
                    x =>
                        self.state.account.permisos.guardaEdita ||
                        (!self.state.account.permisos.guardaEdita &&
                            x.tramiteId !=
                            self.state.TRAMITES.OBSERVACION_INTERNA)
                );

                registro.guardaTramite.map((val, index) => (val.orden = index));

                commit('getBySuccess', registro);
                // Completa orden
                //service.orderTramites(state.item);
                dispatch('setState');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    setState({ dispatch, commit, state, rootGetters }) {
        var tramites = service.orderTramites(state.item);
        var self = this;

        // Si hay mas anulados que reactivados pone en estado anulado
        if (service.anulado(tramites) != 0) {
            state.item.estadoId = 2; // Anulado
            state.item.estado = self.state.estado.all.items[1];
        } else {
            // busca el estado filtrando observaciones, anulados y reactivados y
            // tramites que no tengas estados inicial y final indeterminados,
            // si no hay tramites pone sininiciar
            var tmtes = tramites.filter(x => {
                var tm = rootGetters['tramite/getTramiById'](x.tramiteId);
                var ret = (tm.estadoInicialId > 2 || tm.estadoFinalId > 2) && ((tm.modulo & 8) == 8);
                return ret;
            });

            //var t = rootGetters["tramite/getTramiById"](1);
            //t = rootGetters["tramite/getTramiById"](1001);
            var tmt = 0;

            // Si no hay tramites asigna TRAMITES.SIN_INICIAR
            if (tmtes.length == 0) {
                state.item.estadoId = self.state.ESTADOS.SIN_INICIAR;
                state.item.estado = self.state.estado.all.items.find(
                    x => x.id == self.state.ESTADOS.SIN_INICIAR
                );
            } else {
                tmtes.map(function (val, index) {
                    if (tmt == 0) {
                        //if (tmt < val.tramiteId) {
                        tmt = val.tramiteId;
                        if (!val.completado) {
                            var tr = self.state.tramite.items.find(
                                x => x.id == tmt
                            );
                            state.item.estadoId = tr.estadoInicialId;
                            state.item.estado = tr.estadoInicial;
                        } else {
                            var tr = self.state.tramite.items.find(
                                x => x.id == tmt
                            );
                            state.item.estadoId = tr.estadoFinalId;
                            state.item.estado = tr.estadoFinal;
                        }
                    }
                });
            }
        }
        if (state.item.estadoId == self.state.ESTADOS.FINALIZADO) {
            state.item.deposito = { id: 0, nom: "" };
            state.item.depositoId = null;
            state.item.puestoId = null;
        } else {
            service.setDepositoPuesto(state.item);
        }
    },

    //getxyyyy({ commit }, yyyyId) {
    //    commit('getAllRequest');

    //    service
    //        .getxyyyy(yyyyId)
    //        .then(
    //            registro => commit('getAllSuccess', registro),
    //            error => commit('failure', error)
    //        );
    //},

    postObject({ dispatch, commit }) {
        commit('postRequest', state.item);

        state.item.applicationUserId = this._modules.root.state.account.loggedUser.id;

        return service.postObject(state.item).then(
            registro => commit('postSuccess', registro),
            error =>
                dispatch('alert/errorTime', error.response.data, { root: true })
        );
    },

    putObject({ dispatch, commit }) {
        commit('putRequest', state.item);

        state.item.applicationUserId = this._modules.root.state.account.loggedUser.id;

        service.putObject(state.item).then(
            registro => commit('putSuccess', registro),
            error =>
                dispatch('alert/errorTime', error.response.data, { root: true })
        );
    },

    deleteItem({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            campoTramite => commit('deleteSuccess', id),
            error => commit('failure', { id, error: error.toString() })
        );
    },

    exportaGuardas({ dispatch, commit, state }) {
        commit('exportaGuardasRequest');

        service.guardasExporta().then(
            response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'guardas.xls');
                document.body.appendChild(fileLink);

                fileLink.click();
                commit('exportaGuardasSuccess');
            },
            error => {
                commit('failure', error);
                state.error = true;
                dispatch('alert/errorTime', error.message, {
                    root: true
                });
            }
        );
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
    },

    closeBorraDialog({ commit }) {
        commit('setBorraDialog', false);
    },

    closeDialogTramite({ commit }) {
        commit('setDialogTramite', false);
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setModificado({ state }, val) {
        state.modificado = val;
    },

    editTramite({ dispatch, commit }, itm) {
        if (this.state.account.permisos.guardaCliente) {
            itm.nuevoTramite = false;
            dispatch('setTramite', itm);
            commit('setDialogTramite', true);
        }
    },

    newTramite({ dispatch, commit }, tramiteId) {
        var tmt = state.item.guardaTramite.find(x => x.tramiteId == tramiteId);
        if (tramiteId >= this.state.TRAMITES.INICIABAJA && tramiteId != this.state.TRAMITES.TRASLADO && tmt != null) {
            dispatch('editTramite', tmt);
        } else {
            var nt = JSON.parse(JSON.stringify(state.defltTramite));
            nt.orden = state.item.guardaTramite.length;
            nt.nuevoTramite = true;
            nt.tramiteId = tramiteId;
            nt.completado = false;
            nt.usuario = 0;
            dispatch('setTramite', nt);
            commit('setDialogTramite', true);
        }
    },

    setTramite({ commit }, payload) {
        commit('setTramite', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    },

};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.items = [];
        state.progress = true;
    },

    getAllSuccess(state, registro) {
        state.items = registro;
        state.progress = false;
    },

    failure(state, error) {
        state.id = 0;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        if (registro.modeloValor == null || registro.modeloValor == '')
            state.marcaModelo = registro.marca.nombre + ' ' + registro.modelo.nombre;
        state.modificado = false;

        state.item.detalles = [];
        state.item.detalles1 = [];
        state.id = registro.id;
        state.progress = false;
    },

    getCompactSuccess(state, payload) {
        let self = this;
        state.items = payload.registro.map(function (item) {

            var rec = self.state.estado.all.items.find(x => x.id == item.estadoId);

            if (rec != undefined) {
                item.estado = rec;
            } else {
                item.estado = null;
            }
            return item;

            var rec = self.state.cliente.items.find(x => x.id == item.clienteId);
            if (rec != undefined) {
                item.cliente = rec.nombre;
            } else {
                item.cliente = null;
            }

        });
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },

    postSuccess(state, registro) {
        var reg = {};
        let al = registro.guardaAlerta.find(x => x.alertaId == 1 || x.alertaId == 2);
        if (al) {
            reg.alerta = al.alertaId;
            reg.fAlerta = al.fInicio;
            reg.dAlerta = al.descripcion;
        }
        else {
            reg.alerta = 0;
            reg.fAlerta = null;
            reg.dAlerta = null;
        }

        let self = this;

        reg.id = registro.id;
        reg.tramite = registro.guardaTramite.map(function (itm) {
            var tmt = self.state.tramite.items.find(x => x.id == itm.tramiteId);

            if ((tmt.modulo & 8) == 8 && itm.completado) {
                return itm.tramiteId;
            }
        });

        reg.fInicio = registro.fInicio
        reg.tramite = reg.tramite.filter(x => x != undefined);
        reg.codigo = registro.codigo;
        reg.cliente = registro.cliente.nombre;
        reg.dominio = registro.nombre;
        reg.siniestro = registro.siniestro;
        reg.marca = registro.marca.nombre;
        reg.modelo = registro.modelo.nombre;
        reg.clienteId = registro.clienteId;
        reg.estado = self.state.estado.all.items.find(x => x.id == registro.estado.id);
        reg.color = registro.color;
        reg.dropboxLink = registro.dropboxLink;
        reg.codigo = registro.sCodigo;

        if (registro.modeloValorId != null && registro.modeloValorId != '') {
            var rec = this.state.modelovalor.items.find(x => x.modeloValorId == registro.modeloValorId);
            if (rec != undefined) {
                reg.marcaModelo = rec.marca + ' ' + rec.modelo;
            }
        } else {
            reg.marcaModelo = registro.marca.nombre + ' ' + registro.modelo.nombre;
        }

        if (registro.depositoId === null)
            reg.depositoPuesto = '';
        var dep = this.state.deposito.items.find(x => x.id == registro.depositoId);
        if (dep) {
            reg.depositoPuesto = dep.nombre + ((registro.puestoId != null) ? (' / ' + registro.puestoId) : '');
        }
        state.items.push(reg);
        state.item = registro;
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },

    putSuccess(state, registro) {
        var reg = {};
        let al = registro.guardaAlerta.find(x => x.alertaId == 1 || x.alertaId == 2);
        if (al) {
            reg.alerta = al.alertaId;
            reg.fAlerta = al.fInicio;
            reg.dAlerta = al.descripcion;
        }
        else {
            reg.alerta = 0;
            reg.fAlerta = null;
            reg.dAlerta = null;
        }

        let self = this;
        var i = state.items.find(x => x.id == registro.id);
        var index = state.items.indexOf(i);

        reg.id = registro.id;
        reg.tramite = registro.guardaTramite.map(function (itm) {
            var tmt = self.state.tramite.items.find(x => x.id == itm.tramiteId);

            if ((tmt.modulo & 8) == 8 && itm.completado) {
                return itm.tramiteId;
            }
        });

        reg.fInicio = registro.fInicio

        reg.tramite = reg.tramite.filter(x => x != undefined);
        reg.codigo = registro.codigo;
        reg.cliente = registro.cliente.nombre;
        reg.dominio = registro.nombre;
        reg.siniestro = registro.siniestro;
        reg.marca = registro.marca.nombre;
        reg.modelo = registro.modelo.nombre;
        reg.clienteId = registro.clienteId;
        reg.estado = self.state.estado.all.items.find(x => x.id == registro.estado.id);
        reg.color = registro.color;
        reg.dropboxLink = registro.dropboxLink;
        reg.codigo = registro.sCodigo;

        //if (registro.modeloValorId != null && registro.modeloValorId != '') {
        //    var rec = this.state.modelovalor.items.find(x => x.modeloValorId == registro.modeloValorId);
        //    if (rec != undefined) {
        //        reg.marcaModelo = rec.marca + ' ' + rec.modelo;
        //    }
        //} else {
        //    reg.marcaModelo = registro.marca.nombre + ' ' + registro.modelo.nombre;
        //}

        if (registro.depositoId === null)
            reg.depositoPuesto = '';
        var dep = this.state.deposito.items.find(x => x.id == registro.depositoId);
        if (dep) {
            reg.depositoPuesto = dep.nombre + ((registro.puestoId != null) ? (' / ' + registro.puestoId) : '');
        }

        state.items.splice(index, 1, JSON.parse(JSON.stringify(reg)));

        state.progress = false;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to campoTramite being deleted
        state.items = state.items.map(campoTramite =>
            campoTramite.id === id
                ? { ...campoTramite, deleting: true }
                : campoTramite
        );
    },

    deleteSuccess(state, id) {
        // remove deleted campoTramite from state
        state.items = state.items.filter(
            campoTramite => campoTramite.id !== id
        );
        state.progress = false;
    },

    exportaGuardasRequest(state) {
        state.progress = true;
    },

    exportaGuardasSuccess(state) {
        state.progress = false;
    },

    setDialog(state, payload) {
        state.dialog = payload;
        if (payload == false) {
            state.item = JSON.parse(JSON.stringify(state.deflt));
        }
    },

    setDialogTramite(state, payload) {
        state.dialogTramite = payload;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    },

    setTramite(state, payload) {
        state.tramite = payload;
    },

    //setDepositoPuesto(state, payload) {
    //    state.item.deposito = this.state.deposito.find(x => x.id == payload);
    //},

    pushChild(state, payload) {
        state.item.guardaTramite.push(payload);
    },

};

export const guarda = {
    namespaced: true,
    state,
    actions,
    mutations
};
