import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'api/productor/';

// Exporta metodos
export const productorService = {
    getAllAsync,
    getxcliente,
    getById,
    getAutocomplete,
    putObject,
    postObject,
    deleteObject
};

// Lee todos los elementos
async function getAllAsync() {
    var ret = axios.get(urlAPI + API, authHeader());
    return ret;
}

// Lee solo los elementos relacionados con el id de la entidad padre marca
function getxcliente(id) {
    return axios
        .get(urlAPI + API + 'cliente/' + id, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// Lee un solo elemento de la entidad
function getById(id) {
    return axios.get(urlAPI + API + id, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee un texto incompleto para autocompletar
function getAutocomplete(val) {
    var results = [];
    return axios
        .post(
            urlAPI + API + 'autocompleta/',
            {
                clienteId: val.clienteId,
                nombre: val.val
            },
            authHeader()
        )
        .then(response => {
            // Ordena descendente
            var productores = response.data.sort((a, b) => {
                if (a.nombre > b.nombre) {
                    return -1;
                }
                if (a.nombre < b.nombre) {
                    return 1;
                }
                return 0;
            });




            for (var i = 0; i < productores.length; i++) {
                results.push({
                    id: productores[i].id,
                    nombre: productores[i].nombre
                });
            }
            return results;
        });
}

// Post de la entidad
function postObject(obj) {
    // Copiar todas las propiedades menos id
    var modelo = {
        nombre: obj.nombre,
        //        yyyyId: obj.yyyy.id,
        contacto: obj.contacto,
        direccion: obj.direccion,
        localidadId: obj.localidad.id,
        provinciaId: obj.provincia.id,
        cuit: obj.cuit,
        telefono: obj.telefono,
        email: obj.email,
        cliPrd: obj.cliPrd,
        borrado: obj.borrado
    };

    return axios.post(urlAPI + API, modelo, authHeader()).then(registro => {
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var modelo = {
        id: obj.id,
        nombre: obj.nombre,
        //        yyyyId: obj.yyyy.id,
        contacto: obj.contacto,
        direccion: obj.direccion,
        localidadId: obj.localidad.id,
        provinciaId: obj.provincia.id,
        cuit: obj.cuit,
        telefono: obj.telefono,
        email: obj.email,
        cliPrd: obj.cliPrd,
        borrado: obj.borrado
    };

    return axios
        .put(urlAPI + API + modelo.id, modelo, authHeader())
        .then(registro => {
            return registro.data;
        });
}

// delete de la entidad
function deleteObject(obj) {
    return axios.delete(urlAPI + API + obj, authHeader()).then(registro => {
        return registro.data;
    });
}
