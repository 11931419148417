import axios from 'axios';

import { authHeader } from '../_helpers';
import { urlAPI } from './index';

// 0100    casos
// 0101    casosEdita  
// 0102    casosBorra   
// 0103    casosCliente 
// 0200    entidades    
// 0300    tablas      
// 0400    parametros   
// 0500    configuracion
// 0501    configuracionTramite
// 0600    seguimiento  
// 0700    guarda
// 0701    guardaEdita  
// 0702    guardaBorra   
// 0703    guardaCliente 

var grantUsers = [
    { userTypeId: 1, userType: "Superusuario", grant: ["0100", "0101", "0102", "0103", "0200", "0300", "0400", "0500", "0501", "0600", "0700", "0701", "0702", "0703"] },
    { userTypeId: 2, userType: "Administrador", grant: ["0100", "0101", "0102", "0103", "0200", "0300", "0400", "0500", "0501", "0600", "0700", "0701", "0702", "0703"] },
    { userTypeId: 3, userType: "Supervisor", grant: ["0100", "0101", "0103", "0200", "0300", "0600", "0700", "0701", "0702", "0703"] },
    { userTypeId: 4, userType: "OperadorBaja", grant: ["0100"/*, "0101"*/, "0103", "0200"] },
    { userTypeId: 5, userType: "Productor", grant: ["0100"] },
    { userTypeId: 6, userType: "CompañiaBaja", grant: ["0100"] },
    { userTypeId: 7, userType: "OperadorGuarda", grant: ["0700", "0701", "0703", "0200"] },
    { userTypeId: 8, userType: "CompañiaGuarda", grant: [ "0700"] },
];

var initPermisos = [
    {
        casos: true,                // 0100
        casosEdita: true,           // 0101
        casosBorra: true,           // 0102
        casosCliente: true,         // 0103
        entidades: true,            // 0200
        tablas: true,               // 0300
        parametros: true,           // 0400
        configuracion: true,        // 0500
        configuracionTramite: true, // 0501
        seguimiento: true,          // 0600
        guarda: true,               // 0700
        guardaEdita: true,          // 0701
        guardaBorra: true,          // 0702
        guardaCliente: true,        // 0703
    }, //Superusuario
    {
        casos: true,
        casosEdita: true,
        casosBorra: true,
        casosCliente: true,
        entidades: true,
        tablas: true,
        parametros: true,
        configuracion: true,
        configuracionTramite: false,
        seguimiento: true,
        guarda: true,               // 0700
        guardaEdita: true,          // 0701
        guardaBorra: true,          // 0702
        guardaCliente: true,        // 0703
    }, // Administrador
    {
        casos: true,
        casosEdita: true,
        casosBorra: false,
        casosCliente: true,
        entidades: true,
        tablas: true,
        parametros: false,
        configuracion: false,
        configuracionTramite: false,
        seguimiento: true,
        guarda: true,               // 0700
        guardaEdita: true,          // 0701
        guardaBorra: false,         // 0702
        guardaCliente: true,        // 0703

    }, // Supervisor
    {
        casos: true,
        casosEdita: true,
        casosBorra: false,
        casosCliente: true,
        entidades: true,
        tablas: false,
        parametros: false,
        configuracion: false,
        configuracionTramite: false,
        seguimiento: false,
        guarda: false,              // 0700
        guardaEdita: false,         // 0701
        guardaBorra: false,         // 0702
        guardaCliente: false,       // 0703
    }, // OperadorBaja
    {
        casos: true,
        casosEdita: false,
        casosBorra: false,
        casosCliente: false,
        entidades: false,
        tablas: false,
        parametros: false,
        configuracion: false,
        configuracionTramite: false,
        seguimiento: false,
        guarda: false,               // 0700
        guardaEdita: false,          // 0701
        guardaBorra: false,          // 0702
        guardaCliente: false,        // 0703

    }, // Productor
    {
        casos: true,
        casosEdita: false,
        casosBorra: false,
        casosCliente: false,
        entidades: false,
        tablas: false,
        parametros: false,
        configuracion: false,
        configuracionTramite: false,
        seguimiento: false,
        guarda: false,               // 0700
        guardaEdita: false,          // 0701
        guardaBorra: false,          // 0702
        guardaCliente: false,        // 0703
    }, // Campañia Baja
    {
        casos: false,
        casosEdita: false,
        casosBorra: false,
        casosCliente: false,
        entidades: true,
        tablas: false,
        parametros: false,
        configuracion: false,
        configuracionTramite: false,
        seguimiento: false,
        guarda: true,               // 0700
        guardaEdita: true,          // 0701
        guardaBorra: false,         // 0702
        guardaCliente: true,        // 0703
    }, // OperadorGuarda

    {
        casos: false,
        casosEdita: false,
        casosBorra: false,
        casosCliente: false,
        entidades: false,
        tablas: false,
        parametros: false,
        configuracion: false,
        configuracionTramite: false,
        seguimiento: false,
        guarda: true,                // 0700
        guardaEdita: false,          // 0701
        guardaBorra: false,          // 0702
        guardaCliente: false,        // 0703
    }, // Campañia Guarda

    //    { casos: true, casosEdita: true, casosBorra: true, casosCliente: true, entidades: true, tablas: true, parametros: true, configuracion: true, configuracionTramite: true, configuracionCampos: true }, //Superusuario
    //    { casos: true, casosEdita: true, casosBorra: true, casosCliente: true, entidades: true, tablas: true, parametros: true, configuracion: true, configuracionTramite: false, configuracionCampos: false },// Administrador
    //    { casos: true, casosEdita: true, casosBorra: false, casosCliente: true, entidades: true, tablas: true, parametros: false, configuracion: false, configuracionTramite: false, configuracionCampos: false },// Supervisor
    //    { casos: true, casosEdita: true, casosBorra: false, casosCliente: true, entidades: true, tablas: false, parametros: false, configuracion: false, configuracionTramite: false, configuracionCampos: false },// Operador
    //    { casos: true, casosEdita: false, casosBorra: false, casosCliente: false, entidades: false, tablas: false, parametros: false, configuracion: false, configuracionTramite: false, configuracionCampos: false },// Productor
    //    { casos: true, casosEdita: false, casosBorra: false, casosCliente: false, entidades: false, tablas: false, parametros: false, configuracion: false, configuracionTramite: false, configuracionCampos: false }// Campañia
];

export const accountService = {
    login,
    logout,
    register,
    forgot,
    getCurrentTimeUTC,
    confirma,
    reset,
    resetSc,
    ResendConfirmationEmail,
    setPermisos,
    setGrants
};

function getCurrentTimeUTC() {
    //RETURN:
    //      = number of milliseconds between current UTC time and midnight of January 1, 1970
    var tmLoc = new Date();
    //The offset is in minutes -- convert it to ms
    var s = tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
    return s;
}

function login(username, password) {
    var registro = {
        username: username,
        password: password
    };
    return axios
        .post(urlAPI + `identity/Account/authenticate`, registro)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.data));
            }
            return user.data;
        });
}

function setPermisos(user) {
    var permisos = {
        casos: false,
        casosCliente: false,
        casosEdita: false,
        casosBorra: false,
        entidades: false,
        tablas: false,
        parametros: false,
        configuracion: false,
        configuracionTramite: false,
        seguimiento: false,
        guarda: false,
        guardaCliente: false,
        guardaEdita: false,
        guardaBorra: false,
    };
    if (user != null && user.userRoles != null) {
        user.userRoles.forEach(usr => {
            permisos.casos |= initPermisos[usr - 1].casos;
            permisos.casosCliente |= initPermisos[usr - 1].casosCliente;
            permisos.casosEdita |= initPermisos[usr - 1].casosEdita;
            permisos.casosBorra |= initPermisos[usr - 1].casosBorra;
            permisos.guarda |= initPermisos[usr - 1].guarda;
            permisos.guardaCliente |= initPermisos[usr - 1].guardaCliente;
            permisos.guardaEdita |= initPermisos[usr - 1].guardaEdita;
            permisos.guardaBorra |= initPermisos[usr - 1].guardaBorra;
            permisos.entidades |= initPermisos[usr - 1].entidades;
            permisos.tablas |= initPermisos[usr - 1].tablas;
            permisos.parametros |= initPermisos[usr - 1].parametros;
            permisos.configuracion |=
                initPermisos[usr - 1].configuracion;
            permisos.configuracionTramite |=
                initPermisos[usr - 1].configuracionTramite;
            permisos.seguimiento |= initPermisos[usr - 1].seguimiento;
        });
    }
    return permisos;
}

function setGrants(user) {
    var grants = grantUsers;
    var permisos = [];
    if (user != null && user.userRoles != null) {
        user.userRoles.forEach(usr => {
            grants[usr - 1].grant.forEach(grant => {
                if (permisos.indexOf(grant) < 0)
                    permisos.push(grant);
            });
        });
    }
    return permisos;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    return axios.post(urlAPI + `identity/Account`, user).then(user => {
        return user.data;
    });
}

function forgot(email) {
    return axios.get(urlAPI + `identity/Account/Forgot/` + email).then(user => {
        return user.data;
    });
}

function confirma(validation) {
    var reg = {
        userId: Number(validation.userId),
        code: validation.code
    };
    return axios.post(urlAPI + `identity/Account/Confirm`, reg).then(user => {
        // login successful if there's a jwt token in the response
        if (user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }
        return user.data;
    });
}

function reset(validation) {
    var reg = {
        userId: Number(validation.userId),
        code: validation.code,
        password: validation.password
    };

    return axios
        .post(urlAPI + `identity/Account/ResetPassword`, reg)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user.data;
        });
}

function resetSc(validation) {
    var reg = {
        userId: Number(validation.userId),
        password: validation.password
    };

    return axios
        .post(urlAPI + `identity/Account/ResetPasswordSc`, reg, authHeader())
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user.data;
        });
}

function ResendConfirmationEmail(mail) {
    return axios
        .get(urlAPI + `identity/Account/ResendConfirmationEmail/` + mail)
        .then(user => {
            return user.data;
        });
}

//function handleResponse(response) {
//return response.then(text => {
//    const data = text && JSON.parse(text);
//    if (!response.status === 200) {
//        if (response.status === 401) {
//            // auto logout if 401 response returned from api
//            logout();
//            location.reload(true);
//        }

//        const error = (data && data.message) || response.statusText;
//        return Promise.reject(error);
//    }

//return response.data;
//});
//}
