import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module';
import { account } from './account.module';
import { caso } from './caso.module';
import { campotramite } from './campotramite.module';
import { cliente } from './cliente.module';
import { role } from './role.module';
import { user } from './user.module';
import { desarmadero } from './desarmadero.module';
import { deposito } from './deposito.module';
import { estado } from './estado.module';
import { estadoinforme } from './estadoinforme.module';
import { gestor } from './gestor.module';
import { grua } from './grua.module';
import { guarda } from './guarda.module';
import { image } from './image.module';
import { localidad } from './localidad.module';
import { marca } from './marca.module';
import { modelo } from './modelo.module';
import { modelovalor } from './modelovalor.module';
import { modelovalortipo } from './modeloValorTipo.module';
import { neDestino } from './neDestino.module';
import { neNotificacion } from './neNotificacion.module';
import { productor } from './productor.module';
import { provincia } from './provincia.module';
import { registro } from './registro.module';
import { recuperador } from './recuperador.module';
import { tipobaja } from './tipobaja.module';
import { tipocontribuyente } from './tipocontribuyente.module';
import { tipopersona } from './tipopersona.module';
import { tipvehiculo } from './tipvehiculo.module';
import { titular } from './titular.module';
import { tramitador } from './tramitador.module';
import { tramite } from './tramite.module';
import { transportista } from './transportista.module';
import { zonainfluencia } from './zonainfluencia.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        TRAMITES: {
            ANULAR: 1,
            OBSERVACION: 2,
            OBSERVACION_INTERNA: 3,
            OTROS_GASTOS: 4,
            REACTIVAR: 5,
            INICIABAJA: 12,
            PREINFORME: 13,
            ORDEN_DE_TRASLADO: 14,
            PEDIDO_DE_TRASLADO: 15,
            PEDIDO_04D: 16,
            GESTORIA: 17,
            BAJA_A_COMPANIA_MAIL: 18,
            DOCUMENTACION_FISICA_A_COMPANIA: 19,
            ACREEDOR_PRENDARIO: 20,
            FINALIZADO: 21,

            VALORES_INFOAUTO: 1001,
            COBRO_A_DESARMADEROS: 1002,
            COBRO_GESTORIA: 1003,
            COBRO_OTROS_GASTOS: 1004,
            PAGO_A_COMPANIA: 1005,
            FC_COMPANIA_A_DESARMADERO: 1006,
            COMISION_BAJA: 1007,
            RETIRO: 1030,
            TRASLADO: 1031,
            INICIAGUARDA: 1032,
            INGRESOGUARDA: 1033,
        },
        ESTADOS: {
            ANULADO: 1,
            SIN_INICIAR: 12,
            INICIADO: 13,
            INFORMES_PEDIDOS: 14,
            INFORMES_COMPLETOS: 15,
            PEDIDO_DE_ORDEN_DE_TRASLADO: 16,
            CON_ORDEN_DE_TRASLADO: 17,
            EN_ESPERA_DE_TRASLADO: 18,
            TRASLADADO: 19,
            EN_ESPERA_DE_04D: 20,
            CON_04D: 21,
            EN_GESTORIA: 22,
            BAJA_TERMINADA: 23,
            MAIL_BAJA_EN_COMPANIA: 24,
            DOCUMENTACION_FISICA_EN_CIA: 25,
            EN_ACREEDOR_PRENDARIO: 26,
            PRENDA_LEVANTADA: 27,
            FINALIZADO: 28
        },

        config: {
            logo: "@/assets/logo4.png",
            navColor: "white",
            menuColor: "yellow",
            dark: false
        },
    },
    modules: {
        alert,
        account,
        campotramite,
        caso,
        cliente,
        desarmadero,
        deposito,
        estado,
        estadoinforme,
        gestor,
        grua,
        guarda,
        image,
        localidad,
        marca,
        modelo,
        modelovalor,
        modelovalortipo,
        neDestino,
        neNotificacion,
        productor,
        provincia,
        registro,
        recuperador,
        role,
        tipobaja,
        tipocontribuyente,
        tipopersona,
        tipvehiculo,
        titular,
        tramitador,
        tramite,
        transportista,
        user,
        zonainfluencia
    }
});
