import axios from 'axios';

import { authHeader, token } from '../_helpers';
import { urlAPI } from './index';

// ruta API de la entidad
let API = 'api/guarda/';
let APIQry = 'api/guardaQuery/';

// Exporta metodos
export const guardaService = {
    anulado,
    guardasExporta,
    deleteObject,
    getAll,
    getById,
    getCompact,
    getAutocomplete,
    orderTramites,
    postObject,
    putObject,
    setDepositoPuesto
};

function setDepositoPuesto(guarda) {
    var tmtDepPto = guarda.guardaTramite.find(x => {
        if (x.completado && ((x.depositoId != null && x.depositoId != 0)))
            return x;
    });
    if (tmtDepPto) {
        guarda.deposito = tmtDepPto.deposito;
        guarda.depositoId = tmtDepPto.depositoId;
        guarda.puestoId = tmtDepPto.puestoId;
    }

}

function orderTramites(guarda) {
    // Ordena descendente
    var tramites = guarda.guardaTramite.sort((a, b) => {
        if (a.fInicio > b.fInicio) {
            return 1;
        }
        if (a.fInicio < b.fInicio) {
            return -1;
        }
        // a must be equal to b
        if (a.id > b.id) {
            return 1;
        }
        if (a.id < b.id) {
            return -1;
        }
        return 0;
    });

    // Invierte el orden
    return tramites.reverse();
}

function anulado(tramites) {
    var anulado = 0;
    var reactivado = 0;

    tramites.map(x => {
        anulado += x.tramiteId == 1 ? 1 : 0; // Anulados
        reactivado += x.tramiteId == 5 ? 1 : 0; // Reactivado
    });
    if (anulado > reactivado) return 2;
    return 0;
}

// Lee todos los elementos
function getAll() {
    return axios.get(urlAPI + API, authHeader()).then(registro => {
        return registro.data;
    });
}

//async function getCompactAsync(clientes) {
//    var ret = axios.post(urlAPI + API + 'cliente/', clientes, authHeader())
//    return ret;
//}

// Lee todos los elementos compact
function getCompact(modelo) {
    //return axios.post(urlAPI + API + 'compact', modelo, authHeader()).then(registro => {

    return axios.post(urlAPI + APIQry + 'compact', modelo, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee un solo elemento de la entidad
function getById(id) {
    return axios.get(urlAPI + API + id, authHeader()).then(registro => {
        return registro.data;
    });
}

// Lee un texto incompleto para autocompletar
function getAutocomplete(val) {
    var results = [];
    return axios
        .post(urlAPI + API + 'autocompleta/', { nombre: val }, authHeader())
        .then(response => {
            for (var i = 0; i < response.data.length; i++) {
                results.push({
                    id: response.data[i].id,
                    value:
                        response.data[i].apellido +
                        ', ' +
                        response.data[i].nombre
                });
            }
            return results;
        });
}

// Post de la entidad
function postObject(obj) {
    // Copiar todas las propiedades menos id
    var inicio = obj.guardaTramite.find(x => x.tramiteId == 1031);
    var modelo = {
        //id: obj.id,
        nombre: obj.nombre,
        clienteId: obj.cliente.id == 0 ? null : obj.cliente.id,
        codigo: obj.codigo == "" ? null : obj.codigo,
        siniestro: obj.siniestro,
        estadoId: obj.estadoId == 0 ? null : obj.estadoId,
        modeloValorId: obj.modeloValor.modeloValorId == 0 ? null : obj.modeloValor.modeloValorId,
        marcaId: obj.marca.id == 0 ? null : obj.marca.id,
        modeloId: obj.modelo.id == 0 ? null : obj.modelo.id,
        anio: obj.anio,
        fechaSiniestro: obj.fechaSiniestro,
        dropboxLink: obj.dropboxLink,
        motor: obj.motor,
        chasis: obj.chasis,
        color: obj.color,
        applicationUserId: obj.applicationUserId,
        depositoId: obj.deposito.id == 0 ? null : obj.deposito.id,
        puestoId: obj.puestoId == 0 ? null : obj.puestoId,
        fInicio: inicio == null ? undefined : inicio.fInicio,
        deposito: null,
        applicationUser: null,
        cliente: null,
        estado: null,
        marca: null,
        modelo: null,
        modeloValor : null,
        guid: obj.guid,

        guardaTramite: obj.guardaTramite.map(item => {
            item.tramite = null;
            item.deposito = null;
            item.guarda = null;
            item.applicationUser = null;
            return item;
        }),
        guardaAlerta: obj.guardaAlerta.map(item => {
            item.guarda = null;
            item.applicationUser = null;
            return item;
        }),
        borrado: obj.borrado
    };
    return axios.post(urlAPI + API, modelo, authHeader()).then(registro => {
        // Completa nombre e id de la relacion en select
        registro.data.cliente = {
            id: obj.cliente.id,
            nombre: obj.cliente.nombre
        };
        registro.data.estado = {
            id: obj.estado.id,
            nombre: obj.estado.nombre
        };
        registro.data.marca = {
            id: obj.marca.id,
            nombre: obj.marca.nombre
        };
        registro.data.modelo = {
            id: obj.modelo.id,
            nombre: obj.modelo.nombre
        };
        registro.data.modeloValor = {
            id: obj.modeloValor.id,
            nombre: obj.modeloValor.nombre
        };
        return registro.data;
    });
}

// put de la entidad
function putObject(obj) {
    // Copiar todas las propiedades
    var inicio = obj.guardaTramite.find(x => x.tramiteId == 1031);
    var modelo = {
        id: obj.id,
        nombre: obj.nombre,
        clienteId: obj.cliente.id == 0 ? null : obj.cliente.id,
        codigo: obj.codigo == "" ? null : obj.codigo,
        siniestro: obj.siniestro,
        estadoId: obj.estadoId == 0 ? null : obj.estadoId,
        modeloValorId: obj.modeloValor.modeloValorId == 0 ? null : obj.modeloValor.modeloValorId,
        marcaId: obj.marca.id == 0 ? null : obj.marca.id,
        modeloId: obj.modelo.id == 0 ? null : obj.modelo.id,
        anio: obj.anio,
        fechaSiniestro: obj.fechaSiniestro,
        dropboxLink: obj.dropboxLink,
        motor: obj.motor,
        chasis: obj.chasis,
        color: obj.color,
        applicationUserId: obj.applicationUserId,
        depositoId: obj.deposito.id == 0 ? null : obj.deposito.id,
        puestoId: obj.puestoId == 0 ? null : obj.puestoId,
        fInicio: inicio == null ? undefined : inicio.fInicio,
        deposito: null,
        applicationUser: null,
        cliente: null,
        estado: null,
        marca: null,
        modelo: null,
        modeloValor: null,
        guid: obj.guid,

        guardaTramite: obj.guardaTramite.map(item => {
            item.tramite = null;
            item.deposito = null;
            item.guarda = null;
            item.applicationUser = null;
            return item;
        }),
        guardaAlerta: obj.guardaAlerta.map(item => {
            item.guarda = null;
            item.applicationUser = null;
            return item;
        }),
        borrado: obj.borrado
    };
    return axios
        .put(urlAPI + API + modelo.id, modelo, authHeader())
        .then(registro => {
            // Completa nombre e id de la relacion en select
            registro.data.cliente = {
                id: obj.cliente.id,
                nombre: obj.cliente.nombre
            };
            registro.data.estado = {
                id: obj.estado.id,
                nombre: obj.estado.nombre
            };
            registro.data.marca = {
                id: obj.marca.id,
                nombre: obj.marca.nombre
            };
            registro.data.modelo = {
                id: obj.modelo.id,
                nombre: obj.modelo.nombre
            };
            registro.data.modeloValor = {
                id: obj.modeloValor.id,
                nombre: obj.modeloValor.nombre
            };
            return registro.data;
        });
}
function guardasExporta() {
    var aut = token();
    return axios({
        url: urlAPI + API + 'download/',
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: aut }
    });
}

// delete de la entidad
function deleteObject(obj) {
    return axios.delete(urlAPI + API + obj.id, authHeader()).then(registro => {
        return registro.data;
    });
}
