<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.nombre" label="Nombre">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.apellido"
                                          label="Apellido">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.direccion"
                                          label="Dirección">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <selectProvincia v-model="item.provincia.id"
                                             :texto="item.provincia.nombre"
                                             @texto="item.provincia.nombre = $event">
                            </selectProvincia>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <selectLocalidad v-model="item.localidad.id"
                                             :texto="item.localidad.nombre"
                                             :provinciaId="item.provincia.id"
                                             @texto="item.localidad.nombre = $event">
                            </selectLocalidad>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.celular"
                                          label="Celular">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.telefono"
                                          label="Teléfono">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.email" label="EMail">
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancela</v-btn>
            <v-btn color="blue darken-1" text @click="save">Guarda</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import selectLocalidad from '@/components/Selects/selectLocalidad';
    import selectProvincia from '@/components/Selects/selectProvincia';

    export default {
        name: 'titularDialogo',
        data() {
            return {
            };
        },
        methods: {
            ...mapActions('titular', ['putObject', 'postObject', 'deleteObject']),
            close() {
                setTimeout(() => {
                    this.$emit('closeDlg');
                }, 300);
            },

            save() {
                let self = this;
                this.item.value = self.item.apellido + ', ' + self.item.nombre;
                this.item.fullName = this.item.value;
                if (this.item.id > 0) {
                    this.putObject(this.item);
                    self.$emit('modelo', self.item);
                } else {
                    this.postObject(this.item).then(titular => {
                        self.item.id = titular.id;
                        titular.fullName = self.item.apellido + ', ' + self.item.nombre;
                        self.$emit('modelo', self.item);
                        if (self.all != undefined)
                            self.all.items.push(titular);
                    });
                }
                this.close();
            }
        },

        computed: {
            ...mapState('titular', ['item', 'index']),
            formTitle() {
                return this.index == -1 ? 'Nuevo titular' : 'Edita titular';
            }
        },
        components: {
            selectLocalidad,
            selectProvincia
        }
    };
</script>
