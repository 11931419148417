<template>
    <div>
        <div>
            <div type="button" class="transition-swing
                v-btn
             v-btn--bottom
                v-btn--is-elevated
                v-btn--fab
                v-btn--fixed
                v-btn--has-bg
                v-btn--right
                v-btn--round
                theme--light
                v-size--large"
                 aria-label="Scroll to bottom"
                 title="Scroll to top"
                 style="z-index: 16; margin-bottom: 0px; transform-origin: center center;">
                <!--<v-btn @click="onClick">
                    <span class="v-btn__content">
                        <span aria-hidden="true" class="v-icon notranslate theme--dark">
                            <img src="https://s2.accesoperu.com/logos/btn_whatsapp.png" alt="">
                        </span>
                    </span>
                </v-btn>-->
                <a href="https://api.whatsapp.com/send?phone=5491151039129" target="_blank">
                    <span class="v-btn__content">
                        <span aria-hidden="true" class="v-icon notranslate theme--dark">
                            <img src="https://s2.accesoperu.com/logos/btn_whatsapp.png" alt="">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg">
                                <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"></path>
                            </svg>
                        </span>
                    </span>
                </a>
            </div>
        </div>
        <v-card class="mx-auto" shaped max-width="900">
            <v-img class="white--text align-end"
                   width="900px"
                   src="../assets/portada-web.jpg">
            </v-img>

            <v-card-text class="text--primary" align="left">
                <div>
                    <p style="padding-left:15px;">
                        Desde hace más de 50 años nos dedicamos a la compra de Vehiculos Chocados, siniestrados, dados de baja y recupero de robo a Clientes particulares y Empresas de todo el país.
                    </p>
                    <p style="padding-left:15px;">
                        A fin de brindar nuestro Servicio con total eficiencia, contamos con un equipo de Cotizadores y Gestores especializados, y a su disposición, para asesorarte en todo el proceso de la Operación de Compra - Venta de tu Vehículo.
                    </p>
                    <p style="padding-left:15px;">
                        Además, contamos con grúas propias para efectuar el retiro y traslado de los vehículos, haciéndonos cargo de los costos.
                    </p>
                    <p style="padding-left:15px;">
                        Nuestra Empresa trabaja con las principales Compañías de seguro del País, ofreciendo siempre la mejor cotización del Mercado, para quien ha tenido un siniestro y quiere conservar buena parte de su Patrimonio.

                        Póngase en Contacto a través de nuestro WhatsApp y será atendido por uno de nuestros especialistas.
                    </p>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-toolbar color="yellow" shaped short>
                    <v-spacer></v-spacer>
                    <v-btn icon href="https://www.facebook.com/3040TF/">
                        <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn icon big>
                        <v-icon>mdi-instagram</v-icon>
                    </v-btn>
                </v-toolbar>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    // https://www.humanlevel.com/articulos/analitica-trafico-web/como-medir-el-trafico-de-webs-spa-con-google-tag-manager-y-google-analytics.html
    // http://www.liangshunet.com/en/202003/567276536.htm
    export default {
        name: 'Compra',
        data: () => ({}),

        computed: {
            activeFab() {
                return { color: 'success', icon: 'mdi-share-variant' }
            }
        },
        methods: {
            onClick() {
                //window.open("https://api.whatsapp.com/send?phone=5491151039129");
                //this.$gtm.trackEvent({
                //    event: null, // Event type [default = 'interaction'] (Optional)
                //    category: "Calculator",
                //    action: "click",
                //    label: "Home page SIP calculator",
                //    value: 5000,
                //    noninteraction: false, // Optional
                //});
            },
        },
        mounted() {
            this.$gtm.trackView("MyScreenName", "currentPath");
        },
    }
</script>

<style scoped>
    #lateral .v-btn--example {
        top: 0;
        position: absolute;
        margin: 0 0 16px 32px;
    }
</style>
