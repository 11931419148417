import { zonainfluenciaService } from '../_services';
import { router } from '../_helpers';

let service = zonainfluenciaService;

const state = {
    all: {},
    item: {},
    index: -1,
    defaultItem: {
        id: 0,
        nombre: ''
    },
    progress: false
};

const actions = {
    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },
    //getxyyyy({ commit }, provId) {
    //    commit('getAllRequest');

    //    service
    //        .getxyyyy(provId)
    //        .then(
    //            registro => commit('getAllSuccess', registro),
    //            error => commit('failure', error)
    //        );
    //},
    postObject({ dispatch, commit, state }, obj) {
        commit('postRequest', obj);

        return service.postObject(obj).then(
            registro => {
                commit('postSuccess', registro);
                return registro;
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    deleteObject({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            user => commit('deleteSuccess', id),
            error => commit('deleteFailure', { id, error: error.toString() })
        );
    },
    putObject({ dispatch, commit, state }, obj) {
        commit('putRequest', obj);

        service.putObject(obj).then(
            registro => commit('putSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
            //error => commit('putFailure', { obj, error: error.toString() })
        );
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setIndex({ commit }, payload) {
        commit('setIndex', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    }
};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.all = { loading: true };
        state.progress = true;
    },
    getAllSuccess(state, registro) {
        state.all = { items: registro };
        state.progress = false;
    },
    failure(state, error) {
        state.all = { error };
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },
    postSuccess(state, registro) {
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },
    putSuccess(state, registro) {
        state.progress = false;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(user => user.id !== id);
        state.progress = false;
    },
    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.all.items = state.items.map(user => {
            if (user.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...userCopy } = user;
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error };
            }

            return user;
        });
        state.progress = false;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.index = -1;
        }
    },

    setIndex(state, payload) {
        state.index = payload;
    },

    pushChild(/*state, payload*/) {
        //state.item.casoTramite.push(payload);
    }
};

export const zonainfluencia = {
    namespaced: true,
    state,
    actions,
    mutations
};
