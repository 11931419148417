import { gestorService } from '../_services';
import { router } from '../_helpers';

let service = gestorService;

const state = {
    items: [],
    dialog: false,
    item: {},
    id: 0,
    index: -1,
    modificado: false,
    progress: false,

    deflt: {
        id: 0,
        cuit: '',
        direccion: '',
        email: '',
        localidad: { nombre: '', id: null },
        nombre: '',
        provincia: { nombre: '', id: null },
        zonainfluencia: { nombre: '', id: null },
        cliGes: [],
    }
};

const actions = {
    async initialize({ dispatch, commit, state }) {
        commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
        dispatch('gestor/setDefaultItem', null, { root: true });
    },

    editItem({ dispatch, commit, state, rootState }, itm) {
        if (state.dialog) commit('setDialog', false);

        if (itm === null) {
            commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
            state.item.applicationUserId = rootState.account.loggedUser.id;
            state.item.applicationUser = {
                id: rootState.account.loggedUser.id,
                userName: rootState.account.loggedUser.userName,
                email: rootState.account.loggedUser.email
            };
            dispatch('gestor/setDefaultItem', null, { root: true });
        } else {
            dispatch('getById', itm.id);
        }
        commit('setDialog', true);
    },

    getById({ dispatch, commit, state, rootState }, id) {
        var self = this;

        commit('getByRequest');

        service.getById(id).then(
            registro => {
                commit('getBySuccess', registro);
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    async getAllAsync({ dispatch, commit, state }) {
        commit('getAllRequest');
        try {
            var ret = await service.getAllAsync();
            commit('getAllSuccess', ret.data);
        }
        catch (error) {
            commit('failure', error);
            if (error.response.status == 401) {
                dispatch(
                    'alert/errorTime',
                    'Error de permisos o sesión expirada!',
                    {
                        root: true
                    }
                );
                router.push({ name: 'login' });
            } else {
                dispatch('alert/errorTime', error.message, {
                    root: true
                });
            }
        }
    },

    setDefaultItem({ commit, state, rootState }) {
        state.item = JSON.parse(JSON.stringify(state.deflt));
    },

    getxcliente({ commit }, cliId) {
        commit('getAllRequest');

        service.getxcliente(cliId).then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    postObject({ dispatch, commit, state }) {
        commit('postRequest', state.item);

        return service.postObject(state.item).then(
            registro => {
                commit('setItem', registro);
                commit('postSuccess');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    putObject({ dispatch, commit, state }) {
        commit('putRequest', state.item);

        service.putObject(state.item).then(
            registro => {
                commit('setItem', registro);
                commit('putSuccess');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
            //error => commit('putFailure', { obj, error: error.toString() })
        );
    },

    deleteObject({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            user => commit('deleteSuccess', id),
            error => commit('deleteFailure', { id, error: error.toString() })
        );
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setModificado({ state }, val) {
        state.modificado = val;
    },

    setIndex({ commit }, payload) {
        commit('setIndex', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    },

    saveItem({ dispatch, commit }) {
        if (state.item.id > 0) {
            dispatch('putObject');
        } else {
            dispatch('postObject');
        }
        commit('setDialog', false);
    },

    setCliGes({ commit }, payload) {
        commit('setCliGes', payload);
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
    },
};

const mutations = {
    // GetAll 
    getAllRequest(state) {
        state.all = { loading: true };
        state.progress = true;
    },

    getAllSuccess(state, registro) {
        state.items = registro;
        state.modificado = false;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.modificado = false;
        state.id = registro.id;
        state.progress = false;
    },

    failure(state, error) {
        state.items = [];
        state.all = { error };
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },

    postSuccess(state, registro) {
        state.items.push(state.item);
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },
    putSuccess(state) {
        var i = state.items.find(x => x.id == state.item.id);
        var index = state.items.indexOf(i);
        state.items.splice(index, 1, JSON.parse(JSON.stringify(state.item)));
        state.progress = false;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to user being deleted
        state.items = state.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },

    deleteSuccess(state, id) {
        // remove deleted user from state
        state.items = state.items.filter(user => user.id !== id);
        state.progress = false;
    },

    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.items = state.items.map(user => {
            if (user.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...userCopy } = user;
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error };
            }

            return user;
        });
        state.progress = false;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    },

    setIndex(state, payload) {
        state.index = payload;
    },

    pushChild(/*state, payload*/) {
        //state.item.casoTramite.push(payload);
    },

    setCliGes(state, payload) {
        state.item.cliGes = payload;
    },

    setDialog(state, payload) {
        state.dialog = payload;
        if (payload == false) {
            state.item = JSON.parse(JSON.stringify(state.deflt));
        }
    },

};

export const gestor = {
    namespaced: true,
    state,
    actions,
    mutations
};
